import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Checkbox,
  Modal,
} from "@mui/material";
import React, { useState, Dispatch, SetStateAction, useContext } from "react";
import colors from "src/colors";
import logo from "src/icon/logo.svg";
import axios from "axios";
import logoBack from "src/images/fliption_logo_back.png";
import Loading from "src/components/Loading";
import { Auth } from "aws-amplify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FliptionContext } from "src/context/Store";
import CloseIcon from "@mui/icons-material/Close";

type authProp = {
  setState: Dispatch<SetStateAction<string>>;
};

export default function SignUp(props: authProp) {
  const { setState } = props;
  const { changeInfo } = useContext(FliptionContext);
  const [username, setUsername] = useState("");
  const [isEyeOn1, setIsEyeOn1] = useState(false);
  const [isEyeOn2, setIsEyeOn2] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [company, setCompany] = useState("");
  const [field, setField] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //약관 동의
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [checkDialog1, setCheckDialog1] = useState(false);
  const [checkDialog2, setCheckDialog2] = useState(false);
  async function signUpFunction() {
    setIsLoading(true);
    const data = {
      id: username,
      company,
      field,
      password,
    };
    await axios
      .post(
        "https://gkcwmo1d9c.execute-api.ap-northeast-2.amazonaws.com/dev/user/signup",
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setIsLoading(false);
          Auth.signIn(res.data.username, res.data.password).then(() =>
            changeInfo()
          );
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }
  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        background: colors.secondary.main,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "251px",
        backgroundImage: `url(${logoBack})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      <Modal open={checkDialog1}>
        <Stack
          alignItems="center"
          sx={{
            width: "964px",
            height: "937px",
            backgroundColor: "white",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px",
            pt: "97px",
          }}
        >
          <IconButton
            disabled={isLoading}
            onClick={() => {
              setCheckDialog1(false);
            }}
            sx={{ position: "absolute", right: -60, top: 0 }}
          >
            <CloseIcon sx={{ color: "white", fontSize: 40 }} />
          </IconButton>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 26,
              letterSpacing: "-0.04em",
              lineHeight: "16px",
            }}
          >
            개인 정보 수집 이용 동의
          </Typography>
          <Box height="54px" />
          <Typography
            sx={{ textAlign: "center", fontSize: 14, lineHeight: "20px" }}
          >
            1. 개인 정보 수집 이용 목적
            <br />
            <br />
            <br />
            1. 수집 개인 정보 항목
            <br />
            <br />
            <br />
            1. 개인 정보 보유 기간
            <br />
            <br />
            <br />
            <br />
            1. 동의를 거부할 권리와 거부에 따른 불이익
          </Typography>
          <Box height="339px" />
          <Button
            onClick={() => {
              setCheck1(true);
              setCheckDialog1(false);
            }}
            variant="contained"
            sx={{ width: "423px", height: "58px", borderRadius: "10px" }}
          >
            <Typography
              sx={{ fontSize: 20, fontWeight: 700, letterSpacing: "-0.04em" }}
            >
              동의
            </Typography>
          </Button>
        </Stack>
      </Modal>
      <Modal open={checkDialog2}>
        <Stack
          alignItems="center"
          sx={{
            width: "964px",
            height: "937px",
            backgroundColor: "white",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px",
            pt: "97px",
          }}
        >
          <IconButton
            disabled={isLoading}
            onClick={() => {
              setCheckDialog2(false);
            }}
            sx={{ position: "absolute", right: -60, top: 0 }}
          >
            <CloseIcon sx={{ color: "white", fontSize: 40 }} />
          </IconButton>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 26,
              letterSpacing: "-0.04em",
              lineHeight: "16px",
            }}
          >
            개인 정보 수집 이용 동의
          </Typography>
          <Box height="54px" />
          <Typography
            sx={{ textAlign: "center", fontSize: 14, lineHeight: "20px" }}
          >
            1. 개인 정보 수집 이용 목적
            <br />
            <br />
            <br />
            1. 수집 개인 정보 항목
            <br />
            <br />
            <br />
            1. 개인 정보 보유 기간
            <br />
            <br />
            <br />
            <br />
            1. 동의를 거부할 권리와 거부에 따른 불이익
          </Typography>
          <Box height="339px" />
          <Button
            onClick={() => {
              setCheck2(true);
              setCheckDialog2(false);
            }}
            variant="contained"
            sx={{ width: "423px", height: "58px", borderRadius: "10px" }}
          >
            <Typography
              sx={{ fontSize: 20, fontWeight: 700, letterSpacing: "-0.04em" }}
            >
              동의
            </Typography>
          </Button>
        </Stack>
      </Modal>
      {isLoading && <Loading />}
      <Typography
        sx={{
          fontSize: 40,
          fontWeight: 700,
          color: colors.text.light,
          mb: "-29px",
        }}
      >
        Welcome
      </Typography>
      <img
        src={logo}
        alt=""
        style={{ width: 420, height: 150.26, marginBottom: 30 }}
      />
      <Stack
        sx={{
          alignItems: "center",
          pt: "68px",
          backgroundColor: colors.secondary.light,
          width: "626px",
          pb: "50px",
        }}
      >
        <Typography
          sx={{
            fontSize: 26,
            fontWeight: 700,
            color: "white",
            letterSpacing: "-0.04em",
            mb: "54px",
          }}
        >
          REGISTER
        </Typography>
        <Stack sx={{ width: "358px" }}>
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            USER ID
          </Typography>
          <Box height="13px" />
          <input
            onChange={(e) => setUsername(e.target.value)}
            placeholder="아이디를 입력해주세요."
            type="text"
            style={{
              width: "100%",
              outline: "none",
              backgroundColor: "transparent",
              border: "none",
              fontSize: 16,
              color: colors.text.main,
              marginBottom: 6,
            }}
          />
          <Box height="2px" width="100%" bgcolor={colors.text.light} />
          <Box height="44px" />
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            PASSWORD
          </Typography>
          <Box height="13px" />
          <Stack direction="row" sx={{ position: "relative" }}>
            <input
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력해주세요."
              type={isEyeOn1 ? "text" : "password"}
              style={{
                width: "100%",
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                fontSize: 16,
                color: colors.text.main,
                marginBottom: 6,
              }}
            />
            <IconButton
              onClick={() => setIsEyeOn1(!isEyeOn1)}
              sx={{ position: "absolute", right: 0, top: 0, p: 0 }}
            >
              {isEyeOn1 ? (
                <VisibilityOffIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              ) : (
                <VisibilityIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              )}
            </IconButton>
          </Stack>
          <Box height="2px" width="100%" bgcolor={colors.text.light} />
          <Box height="44px" />
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            PASSWORD CHECK
          </Typography>
          <Box height="13px" />
          <Stack direction="row" sx={{ position: "relative" }}>
            <input
              onChange={(e) => setPassword2(e.target.value)}
              placeholder="비밀번호를 다시 입력해주세요."
              type={isEyeOn2 ? "text" : "password"}
              style={{
                width: "100%",
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                fontSize: 16,
                color: colors.text.main,
                marginBottom: 6,
              }}
            />
            <IconButton
              onClick={() => setIsEyeOn2(!isEyeOn2)}
              sx={{ position: "absolute", right: 0, top: 0, p: 0 }}
            >
              {isEyeOn2 ? (
                <VisibilityOffIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              ) : (
                <VisibilityIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              )}
            </IconButton>
          </Stack>
          <Box height="2px" width="100%" bgcolor={colors.text.light} />
          <Box height="44px" />
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            회사명
          </Typography>
          <Box height="13px" />
          <input
            onChange={(e) => setCompany(e.target.value)}
            placeholder="회사명을 입력해주세요."
            type="text"
            style={{
              width: "100%",
              outline: "none",
              backgroundColor: "transparent",
              border: "none",
              fontSize: 16,
              color: colors.text.main,
              marginBottom: 6,
            }}
          />
          <Box height="2px" width="100%" bgcolor={colors.text.light} />
          <Box height="44px" />
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            적용 분야
          </Typography>
          <Box height="13px" />
          <input
            onChange={(e) => setField(e.target.value)}
            placeholder="적용분야를 입력해주세요."
            type="text"
            style={{
              width: "100%",
              outline: "none",
              backgroundColor: "transparent",
              border: "none",
              fontSize: 16,
              color: colors.text.main,
              marginBottom: 6,
            }}
          />
          <Box height="2px" width="100%" bgcolor={colors.text.light} />
          <Box height="28px" />
          <Stack
            direction="row"
            onClick={() => setCheckDialog1(true)}
            sx={{
              backgroundColor: colors.secondary.main,
              width: "100%",
              height: "36px",
              alignItems: "center",
              pl: "8px",
              cursor: "pointer",
            }}
          >
            <Checkbox
              sx={{ p: "4px", color: "#D9D9D9", mr: "14px", ml: "10px" }}
              size={"small"}
              checked={check1}
              //onChange={(e) => setCheck1(e.target.checked)}
            />
            <Typography
              sx={{
                fontWeight: 700,
                color: colors.text.light,
                letterSpacing: "-0.04em",
                lineHeight: "20px",
                marginBottom: "3px",
              }}
            >
              [필수]&nbsp;
            </Typography>
            <Typography
              sx={{
                color: colors.text.light,
                letterSpacing: "-0.04em",
                lineHeight: "20px",
                marginBottom: "2px",
              }}
            >
              청소년 유해 컨텐츠
            </Typography>
          </Stack>
          <Box height="12px" />
          <Stack
            onClick={() => setCheckDialog2(true)}
            direction="row"
            sx={{
              backgroundColor: colors.secondary.main,
              width: "100%",
              height: "36px",
              alignItems: "center",
              pl: "8px",
              cursor: "pointer",
            }}
          >
            <Checkbox
              sx={{ p: "4px", color: "#D9D9D9", mr: "14px", ml: "10px" }}
              size={"small"}
              checked={check2}
              //onChange={(e) => setCheck2(e.target.checked)}
            />
            <Typography
              sx={{
                fontWeight: 700,
                color: colors.text.light,
                letterSpacing: "-0.04em",
                lineHeight: "20px",
                marginBottom: "3px",
              }}
            >
              [필수]&nbsp;
            </Typography>
            <Typography
              sx={{
                color: colors.text.light,
                letterSpacing: "-0.04em",
                lineHeight: "20px",
                marginBottom: "2px",
              }}
            >
              개인정보, 변환데이터에 대한 동의
            </Typography>
          </Stack>
          <Box height="40px" />
          <Button
            onClick={() => signUpFunction()}
            disabled={
              username === "" ||
              password === "" ||
              password !== password2 ||
              company === "" ||
              field === "" ||
              !check1 ||
              !check2
            }
            sx={{
              backgroundColor: colors.primary.main,
              color: colors.text.main,
              height: "47px",
              borderRadius: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 700,
                letterSpacing: "-0.04em",
              }}
            >
              REGISTER
            </Typography>
          </Button>
          <Button
            onClick={() => setState("signIn")}
            sx={{
              color: colors.text.main,
              height: "47px",
              borderRadius: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 700,
                letterSpacing: "-0.04em",
              }}
            >
              LOG IN
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
