import React from "react";
import { Stack, Typography, Box } from "@mui/material";
//step icons
import Step1 from "src/icon/step/step1.svg";
import Step2 from "src/icon/step/step2.svg";
import Step3 from "src/icon/step/step3.svg";
import Step4 from "src/icon/step/step4.svg";
import colors from "../colors";

type stepType = {
  step: number;
};

const StepText = ["파일 업로드", "가상얼굴 선택", "변환얼굴 지정", "변환 완료"];

const SwapStepper = (props: stepType) => {
  const { step } = props;
  return (
    <Stack
      direction="row"
      sx={{
        width: "643px",
        justifyContent: "space-between",
        margin: "41px auto 28px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "578px",
          height: "5px",
          backgroundColor: colors.secondary.light,
          position: "absolute",
          top: 16,
          left: 35,
        }}
      />
      {StepText.map((text, i) => (
        <Stack key={i} sx={{ alignItems: "center" }}>
          {step !== i ? (
            <Box
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "10px",
                backgroundColor: colors.secondary.light,
                mt: "9px",
                mb: "14px",
              }}
            />
          ) : (
            <Box
              sx={{
                width: "38px",
                height: "38px",
                borderRadius: "19px",
                backgroundColor: colors.primary.main,
                zIndex: 1,
                mb: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {step === 0 ? (
                <img src={Step1} alt="" />
              ) : step === 1 ? (
                <img src={Step2} alt="" />
              ) : step === 2 ? (
                <img src={Step3} alt="" />
              ) : (
                <img src={Step4} alt="" />
              )}
            </Box>
          )}
          <Typography
            sx={{
              color: step === i ? colors.primary.main : colors.text.main,
              fontSize: 16,
              fontWeight: "bold",
              letterSpacing: "-0.04em",
            }}
          >
            STEP {i + 1}
          </Typography>
          <Typography
            sx={{
              color: colors.text.main,
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: "-0.04em",
            }}
          >
            {text}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default SwapStepper;
