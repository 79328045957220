import { Box, Button, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import ProfileBig from "src/icon/icon_profile_big.svg";
import colors from "../colors";
import NavBar from "../components/NavBar";
import logoBack from "src/images/fliption_logo_back.png";
import UndoIcon from "src/icon/icon_undo_white.svg";
import { useNavigate } from "react-router-dom";
import { FliptionContext } from "src/context/Store";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import {
  vContentByUser,
  VContentByUserQuery,
  VContentByUserQueryVariables,
} from "src/graphql";

export default function MyPage() {
  const navigation = useNavigate();
  const { user, vfaces } = useContext(FliptionContext);
  const [vcontentLen, setVcontentLen] = useState(0);
  useEffect(() => {
    if (user) {
      fetchVcontents(user?.id);
    }
  }, [user]);
  async function fetchVcontents(id: string) {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: vContentByUser,
        variables: { userID: id } as VContentByUserQueryVariables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: VContentByUserQuery };
      //console.log(apiData.data);
      const datas = apiData.data.VContentByUser;
      if (datas && datas.items) {
        setVcontentLen(datas.items.length);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div
      style={{
        backgroundColor: colors.secondary.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${logoBack})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        paddingBottom: "183px",
      }}
    >
      <NavBar />
      <Box height="140px" />
      <Stack
        sx={{
          backgroundColor: colors.secondary.light,
          width: "626px",
          pt: "32px",
          pb: "163px",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Button
          onClick={() => navigation(-1)}
          sx={{
            p: 0,
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            left: "130px",
            top: "37px",
          }}
        >
          <img
            src={UndoIcon}
            alt=""
            style={{ width: 20, height: 20, marginRight: 5 }}
          />
          <Typography
            sx={{
              fontSize: 16,
              letterSpacing: "-0.04em",
              color: "white",
              marginRight: "6px",
            }}
          >
            Back
          </Typography>
        </Button>
        <Typography
          sx={{
            fontSize: 22,
            fontWeight: 700,
            letterSpacing: "-0.04em",
            color: "white",
          }}
        >
          MYPAGE
        </Typography>
        <Box height="66px" />
        <img src={ProfileBig} alt="" style={{ width: 145, height: 145 }} />
        <Box height="15px" />
        <Typography
          sx={{
            color: "white",
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: "-0.04em",
          }}
        >
          {user?.company}
        </Typography>
        <Box height="42px" />
        <Stack direction="row">
          <Stack
            sx={{
              width: "180px",
              border: "1px solid #707070",
              alignItems: "center",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              py: "15px",
            }}
          >
            <Typography
              sx={{
                color: "#707070",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              V Content
            </Typography>
            <Typography
              sx={{
                color: "#AAAAAA",
                fontSize: 20,
                fontWeight: 700,
                mt: "-2px",
              }}
            >
              {vcontentLen} 개
            </Typography>
          </Stack>
          <Stack
            sx={{
              width: "179px",
              borderTop: "1px solid #707070",
              borderBottom: "1px solid #707070",
              borderRight: "1px solid #707070",
              alignItems: "center",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              py: "15px",
            }}
          >
            <Typography
              sx={{
                color: "#707070",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              V Face
            </Typography>
            <Typography
              sx={{
                color: "#AAAAAA",
                fontSize: 20,
                fontWeight: 700,
                mt: "-2px",
              }}
            >
              {vfaces?.length} 개
            </Typography>
          </Stack>
        </Stack>
        <Box height="21px" />
        <Button
          onClick={() => navigation("/contents")}
          variant="contained"
          color="primary"
          sx={{ width: "358px", height: "47px", borderRadius: "10px" }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: "-0.04em",
            }}
          >
            V Content
          </Typography>
        </Button>
        <Box height="22px" />
        <Button
          onClick={() => navigation("/virtual")}
          sx={{
            width: "358px",
            height: "47px",
            borderRadius: "10px",
            backgroundColor: "#616161",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: "-0.04em",
            }}
          >
            V Face
          </Typography>
        </Button>
        <Box height="15px" />
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "358px" }}
        >
          <Typography
            onClick={() => navigation("/edit")}
            sx={{
              color: "white",
              fontSize: 13,
              letterSpacing: "-0.04em",
              cursor: "pointer",
            }}
          >
            내 정보 수정
          </Typography>
          <Stack direction="row">
            <Typography
              onClick={() => navigation("/request")}
              sx={{
                color: "white",
                fontSize: 13,
                letterSpacing: "-0.04em",
                cursor: "pointer",
              }}
            >
              문의하기
            </Typography>
            <Typography
              sx={{ color: "white", fontSize: 13, letterSpacing: "-0.04em" }}
            >
              &nbsp;|&nbsp;
            </Typography>
            <Button
              sx={{ p: 0, m: 0 }}
              onClick={() => {
                Auth.signOut().then(() => window.location.reload());
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: 13,
                  letterSpacing: "-0.04em",
                }}
              >
                로그아웃
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: colors.secondary.main,
          width: "626px",
          py: "5px",
        }}
      >
        <Typography sx={{ color: colors.text.light, letterSpacing: "-0.04em" }}>
          © 2021. Fliption Co. all rights reserved.
        </Typography>
      </Stack>
    </div>
  );
}
