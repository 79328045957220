import {
  Box,
  Stack,
  Typography,
  styled,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import React, { ChangeEvent, useContext, useRef, useState } from "react";
import colors from "../colors";
import NavBar from "../components/NavBar";
import logoBack from "src/images/fliption_logo_back.png";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import {
  createRequestVFace,
  CreateRequestVFaceInput,
  CreateRequestVFaceMutation,
} from "src/graphql";
import { FliptionContext } from "src/context/Store";
import Loading from "src/components/Loading";
import { Storage } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircle from "src/icon/icon_check_circle.svg";

var shortid = require("shortid");
type inputProps = {
  placeholder: string;
  value: string;
  setValue: (_value: string) => void;
};
type fileType = {
  blob: File;
  preview: string;
  type: string;
};
const TitleTypo = styled(Typography)({
  color: colors.text.light,
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: "-0.04em",
});
const ContentTypo = styled(Typography)({
  color: colors.text.light,
  letterSpacing: "-0.04em",
  fontSize: 14,
});
const InputComponent = (props: inputProps) => {
  const { placeholder, value, setValue } = props;
  return (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type="text"
      placeholder={placeholder}
      style={{
        width: "100%",
        outline: "none",
        color: "white",
        letterSpacing: "-0.04em",
        padding: "9px 0px",
        backgroundColor: "transparent",
        border: "none",
        borderBottom: "1px solid #BABABA",
        fontSize: 14,
        fontFamily: "NotoSansKR",
      }}
    />
  );
};
export default function RequestVFace() {
  const { user } = useContext(FliptionContext);
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [faceType, setFaceType] = useState("");
  const [refs, setRefs] = useState<fileType[] | null>(null);
  const refRef = useRef<HTMLInputElement>(null);
  const [contents, setContents] = useState<fileType[] | null>(null);
  const contentRef = useRef<HTMLInputElement>(null);
  const [who, setWho] = useState("");
  const [opinion, setOpinion] = useState("");
  //요청 끝났는지
  const [isOver, setIsOver] = useState(false);

  const fileToBlob = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length !== 0) {
      const newArr = Array.from(files);
      return newArr.map((ar) => {
        const result = URL.createObjectURL(ar);
        return {
          blob: ar,
          preview: result,
          type: ar.type.includes("image/") ? "image" : "video",
        };
      });
    } else {
      return null;
    }
  };

  function uploadFile(array: fileType[] | null, path: string) {
    if (array) {
      array.map((ar) => Storage.put(path, ar));
    }
  }

  //s3에 Put하는 함수 추가할것.
  async function makeRequest() {
    setIsLoading(true);
    const path = "requests/" + user?.id + "/" + shortid.generate() + "/";
    const refFiles = refs?.map(
      (r, i) => path + "reference_" + i + "." + r.blob.type.split("/")[1]
    );
    const contentFiles = contents?.map(
      (r, i) => path + "content_" + i + "." + r.blob.type.split("/")[1]
    );
    uploadFile(refs, path);
    uploadFile(contents, path);
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: createRequestVFace,
        variables: {
          input: {
            userID: user?.id!,
            email,
            faceDesc: faceType,
            reference: refFiles!,
            testContents: contentFiles!,
            who,
            opinion: opinion === "" ? null : opinion,
            isFinished: false,
          } as CreateRequestVFaceInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: CreateRequestVFaceMutation };
      if (apiData.data.createRequestVFace) {
        setIsLoading(false);
        setIsOver(true);
        setEmail("");
        setFaceType("");
        setWho("");
        setOpinion("");
        setRefs(null);
        setContents(null);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div
      style={{
        backgroundColor: colors.secondary.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${logoBack})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      {isLoading && <Loading />}
      <Modal open={isOver}>
        <Stack
          sx={{
            width: "964px",
            height: "658px",
            backgroundColor: "white",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              setIsOver(false);
              setEmail("");
              setFaceType("");
              setWho("");
              setOpinion("");
              setRefs(null);
              setContents(null);
            }}
            sx={{ position: "absolute", right: -60, top: 0 }}
          >
            <CloseIcon sx={{ color: "white", fontSize: 40 }} />
          </IconButton>
          <img src={CheckCircle} alt="" style={{ width: 91, height: 91 }} />
          <Typography
            sx={{
              fontSize: 26,
              lineHeight: "16px",
              letterSpacing: "-0.04em",
              fontWeight: 700,
              color: colors.secondary.light,
              mt: "29px",
              mb: "21px",
            }}
          >
            가상 얼굴 신청이 완료되었습니다.
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: "20px",
              letterSpacing: "-0.04em",
              textAlign: "center",
            }}
          >
            영업일 기준 3일 이내에 작성하신 이메일로
            <br />
            가상 얼굴 결과물이 전송됩니다.
          </Typography>
          <Box height="66px" />
          <Button
            onClick={() => {
              setIsOver(false);
              navigation(-1);
            }}
            variant="outlined"
            sx={{
              mb: "10px",
              height: "46px",
              width: "244px",
              borderColor: colors.secondary.light,
            }}
          >
            <Typography
              sx={{
                color: colors.secondary.light,
                fontSize: 14,
                letterSpacing: "-0.04em",
                fontWeight: 700,
              }}
            >
              이전 페이지
            </Typography>
          </Button>
        </Stack>
      </Modal>
      <NavBar />
      <Box height="38px" />
      <Stack
        sx={{
          width: "734px",
          backgroundColor: colors.secondary.light,
          mb: "170px",
          pb: "85px",
        }}
      >
        <Stack
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.5)",
            height: "172px",
            backgroundColor: colors.secondary.main,
            alignItems: "center",
            pt: "22px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 20,
              letterSpacing: "-0.04em",
              color: "white",
            }}
          >
            NOTICE
          </Typography>
          <Box height="12px" />
          <Typography
            sx={{
              fontSize: 16,
              letterSpacing: "-0.04em",
              color: "white",
              textAlign: "center",
            }}
          >
            이 페이지는 원하시는 가상 얼굴을 제작하기 위한 페이지입니다.
            <br />
            해당 내용을 작성하시면 영업일 5일 이내로 입력하신 메일로
            <br />
            변환 결과물을 공유 드립니다.
          </Typography>
        </Stack>
        <Stack sx={{ px: "97px", pt: "42px" }}>
          <TitleTypo>이메일</TitleTypo>
          <InputComponent
            value={email}
            setValue={setEmail}
            placeholder="해당 이메일로 변환 결과물과 가상 얼굴을 보내드립니다."
          />
          <Box height="82px" />
          <TitleTypo>원하는 얼굴상</TitleTypo>
          <InputComponent
            value={faceType}
            setValue={setFaceType}
            placeholder="구체적으로 어떤 분위기의 얼굴을 제작하고자하는지 기술해주세요."
          />
          <Box height="82px" />
          <TitleTypo>래퍼런스 업로드</TitleTypo>
          <Box height="10px" />
          <ContentTypo>
            해당 가상 얼굴을 제작하기 위하여 참고해야하는 인물의 사진을
            업로드해주세요.
            <br />
            래퍼런스 사진이 많고 구체적일수록 원하시는 느낌의 가상 얼굴을
            제작하는데 도움이 됩니다.
          </ContentTypo>
          <Box height="23px" />
          <Stack sx={{ width: "100%", height: "297px", position: "relative" }}>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                flexWrap: "wrap",
                height: "297px",
                position: "relative",
                backgroundColor: colors.secondary.main,
                overflowY: "scroll",
                px: "48px",
                py: "20px",
              }}
            >
              {refs?.map((r, i) => (
                <Stack key={i} sx={{ m: "9px", position: "relative" }}>
                  <IconButton
                    onClick={() =>
                      setRefs((rr) => rr!.filter((rrr) => rrr! !== r!))
                    }
                    sx={{
                      p: 0,
                      position: "absolute",
                      top: "8px",
                      right: "9px",
                      zIndex: 3,
                    }}
                  >
                    <RemoveCircleIcon sx={{ color: colors.secondary.light }} />
                  </IconButton>
                  <img
                    src={r.preview}
                    alt=""
                    style={{
                      width: "130px",
                      height: "130px",
                      borderRadius: "20px",
                    }}
                  />
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "130px",
                      textOverflow: "ellipsis",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {r.blob.name}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "44px",
                height: "20px",
                backgroundColor: colors.primary.main,
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 700,
                  fontSize: 12,
                  letterSpacing: "-0.04em",
                }}
              >
                {refs ? refs.length : 0} 개
              </Typography>
            </Stack>
          </Stack>
          <Box height="17px" />
          <input
            accept="image/*"
            multiple
            ref={refRef}
            onChange={(e) => {
              const data = fileToBlob(e);
              setRefs((c) => (c ? (data !== null ? c.concat(data) : c) : data));
            }}
            type="file"
            style={{ display: "none" }}
          />
          <Button
            onClick={() => refRef.current?.click()}
            variant="outlined"
            sx={{
              borderRadius: "50px",
              height: "43px",
              borderColor: colors.text.light,
            }}
          >
            <Stack direction="row" alignItems="center">
              <FileUploadOutlinedIcon
                sx={{ color: colors.text.light, fontSize: 13, mr: "6.5px" }}
              />
              <Typography
                sx={{
                  fontSize: 14,
                  color: colors.text.light,
                  fontWeight: "700",
                  letterSpacing: "-0.04em",
                }}
              >
                파일 업로드
              </Typography>
            </Stack>
          </Button>
          <Box height="92px" />
          <TitleTypo>테스트 컨텐츠</TitleTypo>
          <Box height="10px" />
          <ContentTypo>
            해당 래퍼런스를 반영한 가상 얼굴을 합성하여 테스트하고 싶은{" "}
            <Typography
              sx={{
                color: colors.primary.main,
                fontWeight: 700,
                display: "inline-block",
                fontSize: 14,
              }}
            >
              컨텐츠 5개
            </Typography>
            를 업로드해주세요.
          </ContentTypo>
          <Box height="23px" />
          <Stack sx={{ width: "100%", height: "386px", position: "relative" }}>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "386px",
                position: "relative",
                flexWrap: "wrap",
                backgroundColor: colors.secondary.main,
                overflowY: "scroll",
                px: "48px",
              }}
            >
              {contents?.map((r, i) => (
                <Stack sx={{ m: "9px", position: "relative" }}>
                  <IconButton
                    onClick={() =>
                      setContents((rr) => rr!.filter((rrr) => rrr! !== r!))
                    }
                    sx={{
                      p: 0,
                      position: "absolute",
                      top: "8px",
                      right: "9px",
                      zIndex: 3,
                    }}
                  >
                    <RemoveCircleIcon sx={{ color: colors.secondary.light }} />
                  </IconButton>
                  {r.type === "image" ? (
                    <img
                      src={r.preview}
                      key={i}
                      alt=""
                      style={{
                        width: "130px",
                        height: "130px",
                        borderRadius: 20,
                      }}
                    />
                  ) : (
                    <video
                      src={r.preview}
                      key={i}
                      controls
                      style={{
                        width: "130px",
                        height: "130px",
                        borderRadius: 20,
                      }}
                    />
                  )}
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "130px",
                      textOverflow: "ellipsis",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {r.blob.name}
                  </Typography>
                </Stack>
              ))}
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "44px",
                  height: "20px",
                  backgroundColor: colors.primary.main,
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: 700,
                    fontSize: 12,
                    letterSpacing: "-0.04em",
                  }}
                >
                  {contents ? contents.length : 0}/5
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box height="17px" />
          <input
            multiple
            accept="image/*, video/*"
            ref={contentRef}
            onChange={(e) => {
              const data = fileToBlob(e);
              setContents((c) =>
                c
                  ? data !== null
                    ? c.concat(data).slice(0, 5)
                    : c
                  : data
                  ? data.slice(0, 5)
                  : data
              );
            }}
            type="file"
            style={{ display: "none" }}
          />
          <Button
            onClick={() => contentRef.current?.click()}
            variant="outlined"
            sx={{
              borderRadius: "50px",
              height: "43px",
              borderColor: colors.text.light,
            }}
          >
            <Stack direction="row" alignItems="center">
              <FileUploadOutlinedIcon
                sx={{ color: colors.text.light, fontSize: 13, mr: "6.5px" }}
              />
              <Typography
                sx={{
                  fontSize: 14,
                  color: colors.text.light,
                  fontWeight: "700",
                  letterSpacing: "-0.04em",
                }}
              >
                파일 업로드
              </Typography>
            </Stack>
          </Button>
          <Box height="92px" />
          <TitleTypo>어떤 방식으로 누구에게 활용할 예정인가요?</TitleTypo>
          <InputComponent
            value={who}
            setValue={setWho}
            placeholder="버추얼 휴먼을 제작하여 어떤 방식으로 사용할 예정인지 구체적으로 작성해주세요."
          />
          <Box height="103px" />
          <TitleTypo>기타 의견</TitleTypo>
          <Box height="10px" />
          <textarea
            value={opinion}
            onChange={(e) => setOpinion(e.target.value)}
            placeholder="기타 의견을 작성해주세요."
            rows={11}
            style={{
              width: "100%",
              outline: "none",
              color: "white",
              resize: "none",
              border: "1px solid #BABABA",
              padding: "18px 30px",
              backgroundColor: "transparent",
              fontFamily: "NotoSansKR",
              fontSize: 14,
            }}
          />
          <Box height="42px" />
          <Stack direction="row" justifyContent="center">
            <Button
              onClick={() => navigation(-1)}
              sx={{
                width: "205px",
                height: "48px",
                borderRadius: "10px",
                backgroundColor: "#222222",
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 700,
                  letterSpacing: "-0.04em",
                  color: "white",
                }}
              >
                BACK
              </Typography>
            </Button>
            <Box width="19px" />
            <Button
              onClick={() => makeRequest()}
              disabled={
                email === "" ||
                faceType === "" ||
                who === "" ||
                refs?.length === 0 ||
                contents?.length === 0
              }
              variant="contained"
              sx={{
                width: "205px",
                height: "48px",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: 700, letterSpacing: "-0.04em" }}
              >
                SUBMIT
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
