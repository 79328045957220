import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CursorIcon from "src/icon/step/icon_pointer.svg";
import Preview from "src/images/step/step02.png";

const Step02 = () => {
  return (
    <Stack
      direction="row"
      sx={{ width: "1300px", margin: "0px auto", pl: "160px", py: "32px" }}
    >
      <Box
        sx={{
          border: "1px solid #BABABA",
          width: "159px",
          height: "181px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: "49px",
          position: "relative",
        }}
      >
        <img src={Preview} alt="" style={{ width: "123px", height: "126px" }} />
        <img
          src={CursorIcon}
          alt=""
          style={{
            position: "absolute",
            bottom: "25px",
            left: "58px",
            width: 17,
            height: 17,
          }}
        />
      </Box>
      <Stack sx={{ pt: "11px" }}>
        <Box
          sx={{
            border: "0.5px solid #FFFFFF",
            borderRadius: "12px",
            mb: "4px",
            width: "78px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: 12,
              letterSpacing: "-0.04em",
              lineHeight: "17px",
            }}
          >
            STEP 2
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "white",
            letterSpacing: "-0.04em",
            fontSize: 22,
            fontWeight: 700,
          }}
        >
          가상 얼굴 선택
        </Typography>
        <Box height="16px" />
        <Typography sx={{ fontSize: 16, color: "#BABABA" }}>
          변환 하고자 하는 가상 얼굴을 선택해주세요.
          <br />
          선책하신 가상얼굴로 모든 파일이 변환 됩니다.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Step02;
