import { Box, Stack, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
//images
import VF1 from "src/images/info/vf01.png";
import VF2 from "src/images/info/vf02.png";
import VF3 from "src/images/info/vf03.png";
import VF4 from "src/images/info/vf04.png";
import VF5 from "src/images/info/vf05.png";
import "./VFaceSlider.css";

export const VFaceSlider = () => {
  const [array, setArray] = useState([VF1, VF2, VF3, VF4, VF5]);
  const [progress, setProgress] = useState(0);
  const getSize = (n: number) => {
    if (n === 2) {
      return 191;
    } else if (n === 1 || n === 3) {
      return 160;
    } else {
      return 140;
    }
  };
  useEffect(() => {
    const circle = setInterval(() => {
      setProgress(0);
      setArray((arr) => {
        const item = arr[4];
        arr.splice(0, 0, item);
        return arr.slice(0, 5);
      });
    }, 2000);
    return () => clearInterval(circle);
  }, []);
  useEffect(() => {
    const circle = setInterval(() => {
      setProgress((p) => p + 5);
    }, 100);
    return () => clearInterval(circle);
  }, []);
  return (
    <Stack
      direction="row-reverse"
      justifyContent="center"
      alignItems="center"
      sx={{ zIndex: 3 }}
    >
      {array.map((v, i) => (
        <Stack
          //id={"fade"}
          key={i}
          sx={{
            position: "relative",
            margin:
              i === 1
                ? "0px -8px 0px -28px"
                : i === 3
                ? "0px -28px 0px -8px"
                : "0px",
            zIndex: i === 2 ? 2 : i === 3 || i === 1 ? 1 : 0,
          }}
        >
          <img
            src={v}
            alt=""
            style={{
              width: getSize(i),
              height: getSize(i),
              borderRadius: getSize(i) / 2,
              opacity: i === 0 || i === 4 ? 0.2 : 1,
              border: "none",
            }}
          />
          {/*i === 2 && (
            <CircularProgress
              value={progress}
              variant="determinate"
              thickness={0.75}
              sx={{
                position: "absolute",
                top: "-4px",
                left: "-4px",
                zIndex: 2,
                color: "white",
              }}
              size={199}
            />
            )*/}
          {i === 2 && (
            <Typography
              sx={{
                position: "absolute",
                top: "200px",
                left: 0,
                width: "199px",
                textAlign: "center",
                color: "white",
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              David Adobe
            </Typography>
          )}
          <Box
            sx={{
              width: getSize(i),
              height: getSize(i),
              borderRadius: getSize(i) / 2,
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "black",
              opacity: i === 2 ? 0 : 0.5,
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
};
