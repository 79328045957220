import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import SampleBackground from "src/images/sign_back.png";
import colors from "src/colors";
import logo from "src/icon/logo.svg";
import { Auth } from "aws-amplify";
//icon
import PersonIcon from "src/icon/signin/icon_person.svg";
import KeyIcon from "src/icon/signin/icon_key.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Loading from "src/components/Loading";
import { FliptionContext } from "src/context/Store";

type authProp = {
  setState: Dispatch<SetStateAction<string>>;
};

export default function Sign(props: authProp) {
  const { setState } = props;
  const { changeInfo } = useContext(FliptionContext);
  const [loading, setLoading] = useState(false);
  const [isEyeOn, setIsEyeOn] = useState(false);
  const [username, setUsername] = useState("");
  const [pw, setPw] = useState("");
  //errors
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const login = async (id: string, pw: string) => {
    setLoading(true);
    await Auth.signIn(id, pw)
      .then((res) => {
        console.log(res);
        changeInfo();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        switch (e.name) {
          case "UserNotFoundException":
            setError2(false);
            setError1(true);
            break;
          case "NotAuthorizedException":
            setError1(false);
            setError2(true);
            break;
        }
      });
  };
  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        background: colors.secondary.main,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      {loading && <Loading />}
      <Stack>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: 626,
            height: 354,
            backgroundImage: `url(${SampleBackground})`,
            backgroundSize: "cover",
          }}
        >
          <img
            src={logo}
            alt=""
            style={{ width: 199, height: 61, zIndex: 3 }}
          />
          <Typography
            sx={{
              color: "white",
              fontSize: 20,
              letterSpacing: "-0.04em",
              zIndex: 3,
              mt: "-20px",
            }}
          >
            For Business
          </Typography>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 626,
              height: 354,
              zIndex: 2,
              backgroundColor: "black",
              opacity: 0.35,
            }}
          />
        </div>
        <Stack
          sx={{
            alignItems: "center",
            pt: "58px",
            pb: "78px",
            backgroundColor: colors.secondary.light,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: 26,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              color: "white",
            }}
          >
            LOG IN
          </Typography>
          <Stack sx={{ width: "358px", mt: "54px" }}>
            <Stack direction="row">
              <img
                src={PersonIcon}
                alt=""
                style={{ width: 20, height: 20, marginRight: 7 }}
              />
              <Typography
                sx={{
                  color: colors.text.light,
                  fontWeight: 700,
                  letterSpacing: "-0.04em",
                  lineHeight: "20px",
                }}
              >
                USER ID
              </Typography>
            </Stack>
            <Box height="13px" />
            <input
              onChange={(e) => setUsername(e.target.value)}
              placeholder="아이디를 입력해주세요."
              type="text"
              style={{
                width: "100%",
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                fontSize: 16,
                color: colors.text.main,
                marginBottom: 6,
              }}
            />
            <Stack direction="row" sx={{ position: "relative" }}>
              <Box
                height="2px"
                width="100%"
                bgcolor={error1 ? colors.error : colors.text.light}
              />
              {error1 && (
                <Typography
                  sx={{
                    color: colors.error,
                    position: "absolute",
                    top: 6,
                    right: 0,
                    letterSpacing: "-0.04em",
                  }}
                >
                  존재하지 않는 유저입니다.
                </Typography>
              )}
            </Stack>
            <Box height="44px" />
            <Stack direction="row">
              <img
                src={KeyIcon}
                alt=""
                style={{ width: 20, height: 20, marginRight: 4, marginLeft: 3 }}
              />
              <Typography
                sx={{
                  color: colors.text.light,
                  fontWeight: 700,
                  letterSpacing: "-0.04em",
                  lineHeight: "20px",
                }}
              >
                PASSWORD
              </Typography>
            </Stack>
            <Box height="13px" />
            <Stack direction="row" sx={{ position: "relative" }}>
              <input
                onChange={(e) => setPw(e.target.value)}
                placeholder="비밀번호를 입력해주세요."
                type={isEyeOn ? "text" : "password"}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && pw !== "" && username !== "") {
                    login(username, pw);
                  }
                }}
                style={{
                  width: "100%",
                  outline: "none",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: 16,
                  color: colors.text.main,
                  marginBottom: 6,
                }}
              />
              <IconButton
                onClick={() => setIsEyeOn(!isEyeOn)}
                sx={{ position: "absolute", right: 0, top: 0, p: 0 }}
              >
                {isEyeOn ? (
                  <VisibilityOffIcon
                    sx={{ color: colors.text.light, fontSize: 20 }}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: colors.text.light, fontSize: 20 }}
                  />
                )}
              </IconButton>
            </Stack>
            <Stack direction="row" sx={{ position: "relative" }}>
              <Box
                height="2px"
                width="100%"
                bgcolor={error2 ? colors.error : colors.text.light}
              />
              {error2 && (
                <Typography
                  sx={{
                    color: colors.error,
                    position: "absolute",
                    top: 6,
                    right: 0,
                    letterSpacing: "-0.04em",
                  }}
                >
                  비밀번호를 다시 입력해주세요.
                </Typography>
              )}
            </Stack>
            <Box height="52px" />
            <Button
              onClick={() => login(username, pw)}
              disabled={username === "" || pw === ""}
              sx={{
                backgroundColor: colors.primary.main,
                color: colors.text.main,
                height: "47px",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 700,
                  letterSpacing: "-0.04em",
                }}
              >
                LOG IN
              </Typography>
            </Button>
            <Button
              onClick={() => setState("signUp")}
              sx={{
                color: colors.text.main,
                height: "47px",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 700,
                  letterSpacing: "-0.04em",
                }}
              >
                REGISTER
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
