import {
  Box,
  Button,
  Stack,
  Typography,
  Pagination,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BookmarkIcon from "src/icon/icon_book.svg";
//bookmark on off icon
import BookOn from "src/icon/bookmark/icon_bookmark_orange.svg";
import BookOff from "src/icon/bookmark/icon_bookmark_outline.svg";
import PersonIcon from "@mui/icons-material/Person";
import colors from "../colors";
import NavBar from "../components/NavBar";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import { FliptionContext } from "src/context/Store";
import { sortVFace, sortVFaceByName } from "src/functions/Sort";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { VFaceWithImage } from "src/types/FileType";
import moment from "moment";
import {
  updateVFace,
  UpdateVFaceInput,
  UpdateVFaceMutation,
} from "src/graphql";

export default function VirtualFace() {
  const [sortFilter, setSortFilter] = useState("recent");
  const { vfaces, toggleBook } = useContext(FliptionContext);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [sorted, setSorted] = useState<VFaceWithImage[] | null>(vfaces);
  //bookmark 된것만 보여주기
  const [isShowBook, setIsShowBook] = useState(false);

  useEffect(() => {
    if (vfaces) {
      if (sortFilter === "recent") {
        let newData = [...vfaces];
        newData = sortVFace(newData);
        setSorted(newData);
      } else if (sortFilter === "name") {
        let newData = [...vfaces];
        newData = sortVFaceByName(newData);
        setSorted(newData);
      }
    }
  }, [sortFilter, vfaces]);
  const toggleBookmark = (book: boolean, vid: string) => {
    toggleBook(book, vid);
    toggleVfaceBook(book, vid);
  };
  async function toggleVfaceBook(book: boolean, vid: string) {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: updateVFace,
        variables: {
          input: {
            id: vid,
            isBookmarked: book,
          } as UpdateVFaceInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: UpdateVFaceMutation };
      console.log(apiData);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div
      style={{
        backgroundColor: colors.secondary.main,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        paddingBottom: "90px",
      }}
    >
      <NavBar />
      <Box height="35px" />
      <Stack
        sx={{
          width: "1300px",
          height: "888px",
          alignItems: "center",
          backgroundColor: colors.secondary.light,
          borderRadius: "51px",
          pt: "48px",
          px: "38px",
          position: "relative",
        }}
      >
        <Stack
          direction="row"
          px="21px"
          sx={{ width: "100%", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setIsShowBook(!isShowBook)}
            sx={{
              border: "1px solid #666666",
              width: "35px",
              height: "35px",
              borderRadius: "5px",
              backgroundColor: isShowBook ? colors.primary.main : "transparent",
              p: 0,
              minWidth: 0,
            }}
          >
            <img
              src={BookmarkIcon}
              alt=""
              style={{ width: 15, height: 17.6 }}
            />
          </Button>
          <Stack direction="row">
            <Button
              onClick={() => setSortFilter("recent")}
              sx={{
                flexDirection: "row",
                height: "35px",
                width: "117px",
                backgroundColor:
                  sortFilter === "recent" ? "#666666" : "transparent",
                border: "1px solid #666666",
                borderRadius: "5px",
              }}
            >
              <WatchLaterIcon
                sx={{ color: "white", mr: "8px", fontSize: 16 }}
              />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  letterSpacing: "-0.04em",
                }}
              >
                최신순
              </Typography>
            </Button>
            <Box width="9px" />
            <Button
              onClick={() => setSortFilter("name")}
              sx={{
                flexDirection: "row",
                height: "35px",
                width: "117px",
                backgroundColor:
                  sortFilter === "name" ? "#666666" : "transparent",
                border: "1px solid #666666",
                borderRadius: "5px",
              }}
            >
              <TextFormatIcon
                sx={{ color: "white", mr: "4px", fontSize: 24, mt: "4px" }}
              />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  letterSpacing: "-0.04em",
                  mr: "4px",
                }}
              >
                이름순
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", mt: "7px", width: "100%", height: "692px" }}
        >
          {sorted
            ?.filter((srt) => (isShowBook ? srt.isBookmarked : srt))
            ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((vf, i) => (
              <Stack
                key={i}
                sx={{
                  mx: "21px",
                  my: "35px",
                  width: "202px",
                  height: "276px",
                  borderRadius: "10px",
                  border: "1px solid #BABABA",
                  position: "relative",
                }}
              >
                <IconButton
                  onClick={() => toggleBookmark(!vf.isBookmarked, vf.id)}
                  sx={{ position: "absolute", top: "7px", left: "7px" }}
                >
                  {vf.isBookmarked ? (
                    <img
                      src={BookOn}
                      alt=""
                      style={{ width: 15, height: 17.76 }}
                    />
                  ) : (
                    <img
                      src={BookOff}
                      alt=""
                      style={{ width: 15, height: 18.44 }}
                    />
                  )}
                </IconButton>
                <img
                  src={vf.source}
                  alt=""
                  style={{
                    width: "200px",
                    height: "203px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <Typography
                  sx={{
                    mt: "14px",
                    mb: "9px",
                    color: colors.text.light,
                    lineHeight: "16px",
                    fontWeight: 700,
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    letterSpacing: "-0.04em",
                  }}
                >
                  {vf.name}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <PersonIcon sx={{ color: colors.text.light, fontSize: 18 }} />
                  <Typography
                    sx={{
                      color: colors.text.light,
                      minWidth: "46px",
                      ml: "2px",
                      fontSize: 12,
                      lineHeight: "16px",
                      letterSpacing: "-0.04em",
                    }}
                  >
                    {vf.gender === "male" ? "Man" : "Woman"}
                  </Typography>
                  <WatchLaterIcon
                    sx={{ color: colors.text.light, fontSize: 18 }}
                  />
                  <Typography
                    sx={{
                      color: colors.text.light,
                      ml: "4px",
                      fontSize: 12,
                      lineHeight: "16px",
                      letterSpacing: "-0.04em",
                    }}
                  >
                    {moment(new Date(vf.createdAt)).format("YYYY.MM.DD")}
                  </Typography>
                </Stack>
              </Stack>
            ))}
        </Stack>
        <Box height="20px" />
        {sorted && (
          <Pagination
            color="primary"
            onChange={(_e, value) => setPage(value)}
            count={Math.ceil(
              sorted.filter((srt) => (isShowBook ? srt.isBookmarked : srt))
                .length / 10
            )}
          />
        )}
      </Stack>
    </div>
  );
}
