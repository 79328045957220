import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Preview from "src/images/step/step03.png";
import colors from "src/colors";
import HelpIcon from "@mui/icons-material/Help";

const Step03 = () => {
  return (
    <Stack
      direction="row"
      sx={{ width: "1300px", margin: "0px auto", pl: "160px", py: "32px" }}
    >
      <Box
        sx={{
          border: "1px solid #BABABA",
          width: "159px",
          height: "181px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: "49px",
        }}
      >
        <img src={Preview} alt="" style={{ width: 123, height: 87 }} />
      </Box>
      <Stack sx={{ pt: "11px" }}>
        <Box
          sx={{
            border: "0.5px solid #FFFFFF",
            borderRadius: "12px",
            mb: "4px",
            width: "78px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: 12,
              letterSpacing: "-0.04em",
              lineHeight: "17px",
            }}
          >
            STEP 3
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "white",
            letterSpacing: "-0.04em",
            fontSize: 22,
            fontWeight: 700,
          }}
        >
          변환 하고자 하는 얼굴 선택
        </Typography>
        <Box height="16px" />
        <Typography sx={{ fontSize: 16, color: "#BABABA" }}>
          이 페이지에서는 파일속에 어떤 얼굴을 변환하고 싶은지 석택하는 페이지
          입니다.
          <br />
          해당 버튼의 차이가 궁금하다면 버튼을 눌러 궁금증을 해결하세요.
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer", mt: "17px" }}
        >
          <HelpIcon sx={{ color: "#A7A7A7", fontSize: 16, mr: "5px" }} />
          <Typography sx={{ color: colors.text.light, fontSize: 12 }}>
            자세히보기
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Step03;
