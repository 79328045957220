import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
//step 미리보기
import Step01 from "src/views/steps/Step01";
import Step02 from "src/views/steps/Step02";
import colors from "../colors";
import NavBar from "../components/NavBar";
import SwapStepper from "../components/SwapStepper";
import SwipeOutlinedIcon from "@mui/icons-material/SwipeOutlined";
//component
import FileUpload from "src/views/FileUpload";
import VFaceSelect from "src/views/VFaceSelect";
//type
import {
  currentVType,
  filesType,
  IDImageType,
  resultVType,
} from "src/types/FileType";
//graphql
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import {
  createVContent,
  CreateVContentInput,
  CreateVContentMutation,
} from "src/graphql";
import { Storage } from "aws-amplify";
import Step03 from "src/views/steps/Step03";
import TypeSelect from "src/views/TypeSelect";
import axios from "axios";
import { blobToFile } from "src/functions/blobToFile";
import Loading from "src/components/Loading";
import ResultPage from "src/views/ResultPage";
import LoadingWhenResult from "src/components/LoadingWhenResult";
import { FliptionContext } from "src/context/Store";
import Step04 from "src/views/steps/Step04";
var shortid = require("shortid");
export default function Home() {
  const [step, setStep] = useState(0);
  const { user } = useContext(FliptionContext);
  //step 0
  const [files, setFiles] = useState<filesType[] | null>(null);
  //step 1
  const { vfaces } = useContext(FliptionContext);
  const [currentV, setCurrentV] = useState<currentVType | null>(null);
  //step 2
  const [transType, setTransType] = useState<string | null>(null);
  const [idImg, setIdImg] = useState<IDImageType | null>(null);
  //step 3
  const [results, setResults] = useState<resultVType[]>([]);
  const [loading, setLoading] = useState(false);
  const next = () => {
    if (step === 2) {
      if (transType === "all") {
        files?.forEach((file) =>
          file.type === "image"
            ? changeAllImage(file.file)
            : changeAllVideo(file.file)
        );
      } else {
        files?.forEach((file) =>
          file.type === "image"
            ? changeSpecificImage(file.file, idImg!.file)
            : changeSpecificVideo(file.file, idImg!.file)
        );
      }
    } else {
      setStep((s) => s + 1);
    }
  };
  useEffect(() => {
    if (results && results.length === files?.length) {
      setLoading(false);
      setStep(3);
    }
  }, [results]);
  async function makeVcontent(
    id: string,
    vid: string,
    filename: string,
    s3path: string,
    type: string
  ) {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: createVContent,
        variables: {
          input: {
            userID: id,
            VFaceID: vid,
            fileName: filename,
            storage: s3path,
            type: type,
            isBookmarked: false,
          } as CreateVContentInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: CreateVContentMutation };
      console.log(apiData);
    } catch (e) {
      console.log(e);
    }
  }
  async function changeAllImage(file: File) {
    setLoading(true);
    const form1 = new FormData();
    form1.append("user_id", user?.id!);
    form1.append("src_img", file);
    form1.append("target_img", currentV?.blob!);
    if (form1) {
      await axios({
        method: "post",
        url: "https://api.fliption.us/meta-413/api/v1/images/swap",
        responseType: "blob",
        data: form1,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          token: "token",
        },
      })
        .then((res) => {
          //console.log("this is result : ", res);
          const key =
            "vcontents/" +
            user?.id +
            "/images/" +
            currentV?.id +
            "/" +
            shortid.generate() +
            ".png";
          makeVcontent(
            user?.id!,
            currentV?.id!,
            currentV?.name! + "_" + file.name,
            key,
            "image"
          );
          Storage.put(key, res.data);
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          const result: resultVType = {
            image: url,
            blob: res.data,
            name: file.name,
            isSuccess: true,
            type: "image",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        })
        .catch((e) => {
          console.log(e);
          const url = window.URL.createObjectURL(
            new Blob([file], { type: file.type })
          );
          const result: resultVType = {
            image: url,
            blob: file,
            name: file.name,
            isSuccess: false,
            type: "image",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        });
    }
  }
  async function changeSpecificImage(file: File, id_file: File) {
    setLoading(true);
    const form1 = new FormData();
    form1.append("user_id", user?.id!);
    form1.append("id_img", id_file);
    form1.append("src_img", file);
    form1.append("target_img", currentV?.blob!);
    if (form1) {
      await axios({
        method: "post",
        url: "https://api.fliption.us/meta-413/api/v1/images/swap-specific",
        responseType: "blob",
        data: form1,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          token: "token",
        },
      })
        .then((res) => {
          //console.log("this is result : ", res);
          const key =
            "vcontents/" +
            user?.id +
            "/images/" +
            currentV?.id +
            "/" +
            shortid.generate() +
            ".png";
          makeVcontent(
            user?.id!,
            currentV?.id!,
            currentV?.name! + "_" + file.name,
            key,
            "image"
          );
          Storage.put(key, res.data);
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          const result: resultVType = {
            image: url,
            blob: res.data,
            name: file.name,
            isSuccess: true,
            type: "image",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        })
        .catch((e) => {
          console.log(e);
          const url = window.URL.createObjectURL(
            new Blob([file], { type: file.type })
          );
          const result: resultVType = {
            image: url,
            blob: file,
            name: file.name,
            isSuccess: false,
            type: "image",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        });
    }
  }
  async function changeAllVideo(file: File) {
    setLoading(true);
    const form1 = new FormData();
    form1.append("user_id", user?.id!);
    form1.append("src_video", file);
    form1.append("target_img", currentV?.blob!);
    if (form1) {
      await axios({
        method: "post",
        url: "https://api.fliption.us/meta-413/api/v1/videos/swap",
        responseType: "blob",
        data: form1,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          token: "token",
        },
      })
        .then((res) => {
          //console.log("this is result : ", res);
          const key =
            "vcontents/" +
            user?.id +
            "/videos/" +
            currentV?.id +
            "/" +
            shortid.generate() +
            "." +
            res.data.type.split("/")[1];
          makeVcontent(
            user?.id!,
            currentV?.id!,
            currentV?.name! + "_" + file.name,
            key,
            "video"
          );
          Storage.put(key, res.data);
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          const result: resultVType = {
            image: url,
            blob: res.data,
            name: file.name,
            isSuccess: true,
            type: "video",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        })
        .catch((e) => {
          console.log(e);
          const url = window.URL.createObjectURL(
            new Blob([file], { type: file.type })
          );
          const result: resultVType = {
            image: url,
            blob: file,
            name: file.name,
            isSuccess: false,
            type: "video",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        });
    }
  }
  async function changeSpecificVideo(file: File, id_file: File) {
    setLoading(true);
    const form1 = new FormData();
    form1.append("user_id", user?.id!);
    form1.append("id_img", id_file);
    form1.append("src_video", file);
    form1.append("target_img", currentV?.blob!);
    if (form1) {
      await axios({
        method: "post",
        url: "https://api.fliption.us/meta-413/api/v1/videos/specified-swap",
        responseType: "blob",
        data: form1,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          token: "token",
        },
      })
        .then((res) => {
          //console.log("this is result : ", res);
          const key =
            "vcontents/" +
            user?.id +
            "/videos/" +
            currentV?.id +
            "/" +
            shortid.generate() +
            "." +
            res.data.type.split("/")[1];
          makeVcontent(
            user?.id!,
            currentV?.id!,
            currentV?.name! + "_" + file.name,
            key,
            "video"
          );
          Storage.put(key, res.data);
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          const result: resultVType = {
            image: url,
            blob: res.data,
            name: file.name,
            isSuccess: true,
            type: "video",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        })
        .catch((e) => {
          console.log(e);
          const url = window.URL.createObjectURL(
            new Blob([file], { type: file.type })
          );
          const result: resultVType = {
            image: url,
            blob: file,
            name: file.name,
            isSuccess: false,
            type: "video",
          };
          setResults((r) => (r ? r.concat(result) : [result]));
        });
    }
  }
  const reconvert = async () => {
    const failed = results.filter((r) => r.isSuccess === false);
    setResults((r) => r.filter((r) => r.isSuccess === true));
    if (transType === "all") {
      failed?.forEach((result) =>
        result.type === "image"
          ? changeAllImage(result.blob)
          : changeAllVideo(result.blob)
      );
    } else if (transType === "specific") {
      failed?.forEach((result) =>
        result.type === "image"
          ? changeSpecificImage(result.blob, idImg!.file)
          : changeSpecificVideo(result.blob, idImg!.file)
      );
    }
  };
  const nextDisabled =
    (step === 0 && (!files || files.length === 0)) ||
    (step === 1 && currentV === null) ||
    (step === 2 && transType === null);
  return (
    <Stack
      sx={{
        backgroundColor: colors.secondary.main,
        alignItems: "center",
        minHeight: "100vh",
        pb: "63px",
        overflowX: "scroll",
      }}
    >
      {loading && (
        <LoadingWhenResult
          progress={(100 * results.length) / (files ? files.length : 1)}
        />
      )}
      <NavBar />
      <SwapStepper step={step} />
      {step === 0 ? (
        <Step01 />
      ) : step === 1 ? (
        <Step02 />
      ) : step === 2 ? (
        <Step03 />
      ) : (
        <Step04 />
      )}
      <Stack
        sx={{
          width: "1300px",
          height: "594px",
          alignItems: "center",
          backgroundColor: colors.secondary.light,
          borderRadius: "51px",
          pt: "52px",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: colors.text.main,
            fontSize: 22,
            letterSpacing: "-0.04em",
            fontWeight: 700,
          }}
        >
          {step === 0
            ? "파일 업로드"
            : step === 1
            ? "가상 얼굴 선택"
            : step === 2
            ? "변환 하고자 하는 얼굴 선택"
            : "Result"}
        </Typography>
        {step === 3 ? (
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                color: "#9F9F9F",
                fontWeight: 500,
                letterSpacing: "-0.04em",
              }}
            >
              Slide to View All Result
            </Typography>
            <SwipeOutlinedIcon
              sx={{ color: "#9F9F9F", fontSize: 20, ml: "4px" }}
            />
          </Stack>
        ) : step === 2 ? (
          <Typography
            sx={{ color: "#9F9F9F", fontWeight: 500, letterSpacing: "-0.04em" }}
          >
            ‘모든 얼굴 변환'과 ‘특정 얼굴 변환'중 하나를 선택해주세요
          </Typography>
        ) : (
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                color: "#9F9F9F",
                fontWeight: 500,
                letterSpacing: "-0.04em",
              }}
            >
              Slide to View All V Face
            </Typography>
            <SwipeOutlinedIcon
              sx={{ color: "#9F9F9F", fontSize: 20, ml: "4px" }}
            />
          </Stack>
        )}
        <Stack
          sx={{ width: "1620px", alignItems: "center", position: "relative" }}
        >
          {step === 0 ? (
            <FileUpload files={files} setFiles={setFiles} />
          ) : step === 1 ? (
            <VFaceSelect
              vFace={vfaces}
              currentV={currentV}
              setCurrentV={setCurrentV}
            />
          ) : step === 2 ? (
            <TypeSelect
              transType={transType}
              setTransType={setTransType}
              idImg={idImg}
              setIdImg={setIdImg}
            />
          ) : (
            <ResultPage results={results} reconvert={reconvert} />
          )}
          {
            //previos button
          }
          {step !== 3 && (
            <Button
              onClick={() => setStep((s) => s - 1)}
              disabled={step === 0}
              sx={{
                flexDirection: "row",
                position: "absolute",
                left: 0,
                top: "113px",
              }}
            >
              <Box
                sx={{
                  width: 0,
                  height: 0,
                  backgroundColor: "transparent",
                  borderStyle: "solid",
                  borderTopWidth: 30,
                  borderRightWidth: 40,
                  borderBottomWidth: 30,
                  borderLeftWidth: 0,
                  borderTopColor: "transparent",
                  borderRightColor:
                    step === 0 ? colors.secondary.light : colors.primary.main,
                  borderBottomColor: "transparent",
                  borderLeftColor: "transparent",
                }}
              />
              <Typography
                sx={{
                  color:
                    step === 0 ? colors.secondary.light : colors.text.light,
                  fontSize: 18,
                  fontWeight: 700,
                  lineHeight: "20px",
                  letterSpacing: "-0.04em",
                  ml: "15px",
                }}
              >
                PREV
                <br />
                STEP
              </Typography>
            </Button>
          )}
          {
            //next button
          }
          {step !== 3 && (
            <Button
              onClick={next}
              disabled={nextDisabled}
              sx={{
                flexDirection: "row",
                position: "absolute",
                right: 0,
                top: "113px",
              }}
            >
              <Typography
                sx={{
                  color: nextDisabled
                    ? colors.secondary.light
                    : colors.text.light,
                  fontSize: 18,
                  fontWeight: 700,
                  lineHeight: "20px",
                  letterSpacing: "-0.04em",
                  mr: "15px",
                  textAlign: "right",
                }}
              >
                NEXT
                <br />
                STEP
              </Typography>
              <Box
                sx={{
                  width: 0,
                  height: 0,
                  backgroundColor: "transparent",
                  borderStyle: "solid",
                  borderTopWidth: 30,
                  borderRightWidth: 0,
                  borderBottomWidth: 30,
                  borderLeftWidth: 40,
                  borderTopColor: "transparent",
                  borderRightColor: "transparent",
                  borderBottomColor: "transparent",
                  borderLeftColor: nextDisabled
                    ? colors.secondary.light
                    : colors.primary.main,
                }}
              />
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
