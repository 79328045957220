/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  company: string,
  field: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelUserConditionInput = {
  company?: ModelStringInput | null,
  field?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type User = {
  __typename: "User",
  id: string,
  company: string,
  field: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserInput = {
  id: string,
  company?: string | null,
  field?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateVFaceInput = {
  id?: string | null,
  userID: string,
  name: string,
  image: string,
  gender: string,
  isBookmarked: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelVFaceConditionInput = {
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  isBookmarked?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVFaceConditionInput | null > | null,
  or?: Array< ModelVFaceConditionInput | null > | null,
  not?: ModelVFaceConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type VFace = {
  __typename: "VFace",
  id: string,
  userID: string,
  name: string,
  image: string,
  gender: string,
  isBookmarked: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVFaceInput = {
  id: string,
  userID?: string | null,
  name?: string | null,
  image?: string | null,
  gender?: string | null,
  isBookmarked?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteVFaceInput = {
  id: string,
};

export type CreateVContentInput = {
  id?: string | null,
  userID: string,
  VFaceID: string,
  fileName: string,
  storage: string,
  isBookmarked: boolean,
  type: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelVContentConditionInput = {
  userID?: ModelIDInput | null,
  VFaceID?: ModelIDInput | null,
  fileName?: ModelStringInput | null,
  storage?: ModelStringInput | null,
  isBookmarked?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVContentConditionInput | null > | null,
  or?: Array< ModelVContentConditionInput | null > | null,
  not?: ModelVContentConditionInput | null,
};

export type VContent = {
  __typename: "VContent",
  id: string,
  userID: string,
  VFaceID: string,
  fileName: string,
  storage: string,
  isBookmarked: boolean,
  type: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVContentInput = {
  id: string,
  userID?: string | null,
  VFaceID?: string | null,
  fileName?: string | null,
  storage?: string | null,
  isBookmarked?: boolean | null,
  type?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteVContentInput = {
  id: string,
};

export type CreateVContentComplainInput = {
  id?: string | null,
  userID: string,
  VFaceID: string,
  VContentID: string,
  description: string,
  email: string,
  isFinished: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelVContentComplainConditionInput = {
  userID?: ModelIDInput | null,
  VFaceID?: ModelIDInput | null,
  VContentID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  email?: ModelStringInput | null,
  isFinished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVContentComplainConditionInput | null > | null,
  or?: Array< ModelVContentComplainConditionInput | null > | null,
  not?: ModelVContentComplainConditionInput | null,
};

export type VContentComplain = {
  __typename: "VContentComplain",
  id: string,
  userID: string,
  VFaceID: string,
  VContentID: string,
  description: string,
  email: string,
  isFinished: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVContentComplainInput = {
  id: string,
  userID?: string | null,
  VFaceID?: string | null,
  VContentID?: string | null,
  description?: string | null,
  email?: string | null,
  isFinished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteVContentComplainInput = {
  id: string,
};

export type CreateRequestVFaceInput = {
  id?: string | null,
  userID: string,
  email: string,
  faceDesc: string,
  reference: Array< string | null >,
  testContents: Array< string | null >,
  who: string,
  opinion?: string | null,
  isFinished: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelRequestVFaceConditionInput = {
  userID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  faceDesc?: ModelStringInput | null,
  reference?: ModelStringInput | null,
  testContents?: ModelStringInput | null,
  who?: ModelStringInput | null,
  opinion?: ModelStringInput | null,
  isFinished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRequestVFaceConditionInput | null > | null,
  or?: Array< ModelRequestVFaceConditionInput | null > | null,
  not?: ModelRequestVFaceConditionInput | null,
};

export type RequestVFace = {
  __typename: "RequestVFace",
  id: string,
  userID: string,
  email: string,
  faceDesc: string,
  reference: Array< string | null >,
  testContents: Array< string | null >,
  who: string,
  opinion?: string | null,
  isFinished: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateRequestVFaceInput = {
  id: string,
  userID?: string | null,
  email?: string | null,
  faceDesc?: string | null,
  reference?: Array< string | null > | null,
  testContents?: Array< string | null > | null,
  who?: string | null,
  opinion?: string | null,
  isFinished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteRequestVFaceInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  company?: ModelStringInput | null,
  field?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelVFaceFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  isBookmarked?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVFaceFilterInput | null > | null,
  or?: Array< ModelVFaceFilterInput | null > | null,
  not?: ModelVFaceFilterInput | null,
};

export type ModelVFaceConnection = {
  __typename: "ModelVFaceConnection",
  items:  Array<VFace | null >,
  nextToken?: string | null,
};

export type ModelVContentFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  VFaceID?: ModelIDInput | null,
  fileName?: ModelStringInput | null,
  storage?: ModelStringInput | null,
  isBookmarked?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVContentFilterInput | null > | null,
  or?: Array< ModelVContentFilterInput | null > | null,
  not?: ModelVContentFilterInput | null,
};

export type ModelVContentConnection = {
  __typename: "ModelVContentConnection",
  items:  Array<VContent | null >,
  nextToken?: string | null,
};

export type ModelVContentComplainFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  VFaceID?: ModelIDInput | null,
  VContentID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  email?: ModelStringInput | null,
  isFinished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVContentComplainFilterInput | null > | null,
  or?: Array< ModelVContentComplainFilterInput | null > | null,
  not?: ModelVContentComplainFilterInput | null,
};

export type ModelVContentComplainConnection = {
  __typename: "ModelVContentComplainConnection",
  items:  Array<VContentComplain | null >,
  nextToken?: string | null,
};

export type ModelRequestVFaceFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  faceDesc?: ModelStringInput | null,
  reference?: ModelStringInput | null,
  testContents?: ModelStringInput | null,
  who?: ModelStringInput | null,
  opinion?: ModelStringInput | null,
  isFinished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRequestVFaceFilterInput | null > | null,
  or?: Array< ModelRequestVFaceFilterInput | null > | null,
  not?: ModelRequestVFaceFilterInput | null,
};

export type ModelRequestVFaceConnection = {
  __typename: "ModelRequestVFaceConnection",
  items:  Array<RequestVFace | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    company: string,
    field: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    company: string,
    field: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    company: string,
    field: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVFaceMutationVariables = {
  input: CreateVFaceInput,
  condition?: ModelVFaceConditionInput | null,
};

export type CreateVFaceMutation = {
  createVFace?:  {
    __typename: "VFace",
    id: string,
    userID: string,
    name: string,
    image: string,
    gender: string,
    isBookmarked: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVFaceMutationVariables = {
  input: UpdateVFaceInput,
  condition?: ModelVFaceConditionInput | null,
};

export type UpdateVFaceMutation = {
  updateVFace?:  {
    __typename: "VFace",
    id: string,
    userID: string,
    name: string,
    image: string,
    gender: string,
    isBookmarked: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVFaceMutationVariables = {
  input: DeleteVFaceInput,
  condition?: ModelVFaceConditionInput | null,
};

export type DeleteVFaceMutation = {
  deleteVFace?:  {
    __typename: "VFace",
    id: string,
    userID: string,
    name: string,
    image: string,
    gender: string,
    isBookmarked: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVContentMutationVariables = {
  input: CreateVContentInput,
  condition?: ModelVContentConditionInput | null,
};

export type CreateVContentMutation = {
  createVContent?:  {
    __typename: "VContent",
    id: string,
    userID: string,
    VFaceID: string,
    fileName: string,
    storage: string,
    isBookmarked: boolean,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVContentMutationVariables = {
  input: UpdateVContentInput,
  condition?: ModelVContentConditionInput | null,
};

export type UpdateVContentMutation = {
  updateVContent?:  {
    __typename: "VContent",
    id: string,
    userID: string,
    VFaceID: string,
    fileName: string,
    storage: string,
    isBookmarked: boolean,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVContentMutationVariables = {
  input: DeleteVContentInput,
  condition?: ModelVContentConditionInput | null,
};

export type DeleteVContentMutation = {
  deleteVContent?:  {
    __typename: "VContent",
    id: string,
    userID: string,
    VFaceID: string,
    fileName: string,
    storage: string,
    isBookmarked: boolean,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVContentComplainMutationVariables = {
  input: CreateVContentComplainInput,
  condition?: ModelVContentComplainConditionInput | null,
};

export type CreateVContentComplainMutation = {
  createVContentComplain?:  {
    __typename: "VContentComplain",
    id: string,
    userID: string,
    VFaceID: string,
    VContentID: string,
    description: string,
    email: string,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVContentComplainMutationVariables = {
  input: UpdateVContentComplainInput,
  condition?: ModelVContentComplainConditionInput | null,
};

export type UpdateVContentComplainMutation = {
  updateVContentComplain?:  {
    __typename: "VContentComplain",
    id: string,
    userID: string,
    VFaceID: string,
    VContentID: string,
    description: string,
    email: string,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVContentComplainMutationVariables = {
  input: DeleteVContentComplainInput,
  condition?: ModelVContentComplainConditionInput | null,
};

export type DeleteVContentComplainMutation = {
  deleteVContentComplain?:  {
    __typename: "VContentComplain",
    id: string,
    userID: string,
    VFaceID: string,
    VContentID: string,
    description: string,
    email: string,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRequestVFaceMutationVariables = {
  input: CreateRequestVFaceInput,
  condition?: ModelRequestVFaceConditionInput | null,
};

export type CreateRequestVFaceMutation = {
  createRequestVFace?:  {
    __typename: "RequestVFace",
    id: string,
    userID: string,
    email: string,
    faceDesc: string,
    reference: Array< string | null >,
    testContents: Array< string | null >,
    who: string,
    opinion?: string | null,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRequestVFaceMutationVariables = {
  input: UpdateRequestVFaceInput,
  condition?: ModelRequestVFaceConditionInput | null,
};

export type UpdateRequestVFaceMutation = {
  updateRequestVFace?:  {
    __typename: "RequestVFace",
    id: string,
    userID: string,
    email: string,
    faceDesc: string,
    reference: Array< string | null >,
    testContents: Array< string | null >,
    who: string,
    opinion?: string | null,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRequestVFaceMutationVariables = {
  input: DeleteRequestVFaceInput,
  condition?: ModelRequestVFaceConditionInput | null,
};

export type DeleteRequestVFaceMutation = {
  deleteRequestVFace?:  {
    __typename: "RequestVFace",
    id: string,
    userID: string,
    email: string,
    faceDesc: string,
    reference: Array< string | null >,
    testContents: Array< string | null >,
    who: string,
    opinion?: string | null,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    company: string,
    field: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      company: string,
      field: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVFaceQueryVariables = {
  id: string,
};

export type GetVFaceQuery = {
  getVFace?:  {
    __typename: "VFace",
    id: string,
    userID: string,
    name: string,
    image: string,
    gender: string,
    isBookmarked: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVFacesQueryVariables = {
  filter?: ModelVFaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVFacesQuery = {
  listVFaces?:  {
    __typename: "ModelVFaceConnection",
    items:  Array< {
      __typename: "VFace",
      id: string,
      userID: string,
      name: string,
      image: string,
      gender: string,
      isBookmarked: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVContentQueryVariables = {
  id: string,
};

export type GetVContentQuery = {
  getVContent?:  {
    __typename: "VContent",
    id: string,
    userID: string,
    VFaceID: string,
    fileName: string,
    storage: string,
    isBookmarked: boolean,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVContentsQueryVariables = {
  filter?: ModelVContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVContentsQuery = {
  listVContents?:  {
    __typename: "ModelVContentConnection",
    items:  Array< {
      __typename: "VContent",
      id: string,
      userID: string,
      VFaceID: string,
      fileName: string,
      storage: string,
      isBookmarked: boolean,
      type: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVContentComplainQueryVariables = {
  id: string,
};

export type GetVContentComplainQuery = {
  getVContentComplain?:  {
    __typename: "VContentComplain",
    id: string,
    userID: string,
    VFaceID: string,
    VContentID: string,
    description: string,
    email: string,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVContentComplainsQueryVariables = {
  filter?: ModelVContentComplainFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVContentComplainsQuery = {
  listVContentComplains?:  {
    __typename: "ModelVContentComplainConnection",
    items:  Array< {
      __typename: "VContentComplain",
      id: string,
      userID: string,
      VFaceID: string,
      VContentID: string,
      description: string,
      email: string,
      isFinished: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRequestVFaceQueryVariables = {
  id: string,
};

export type GetRequestVFaceQuery = {
  getRequestVFace?:  {
    __typename: "RequestVFace",
    id: string,
    userID: string,
    email: string,
    faceDesc: string,
    reference: Array< string | null >,
    testContents: Array< string | null >,
    who: string,
    opinion?: string | null,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRequestVFacesQueryVariables = {
  filter?: ModelRequestVFaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequestVFacesQuery = {
  listRequestVFaces?:  {
    __typename: "ModelRequestVFaceConnection",
    items:  Array< {
      __typename: "RequestVFace",
      id: string,
      userID: string,
      email: string,
      faceDesc: string,
      reference: Array< string | null >,
      testContents: Array< string | null >,
      who: string,
      opinion?: string | null,
      isFinished: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VFaceByUserQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVFaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VFaceByUserQuery = {
  VFaceByUser?:  {
    __typename: "ModelVFaceConnection",
    items:  Array< {
      __typename: "VFace",
      id: string,
      userID: string,
      name: string,
      image: string,
      gender: string,
      isBookmarked: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VContentByUserQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VContentByUserQuery = {
  VContentByUser?:  {
    __typename: "ModelVContentConnection",
    items:  Array< {
      __typename: "VContent",
      id: string,
      userID: string,
      VFaceID: string,
      fileName: string,
      storage: string,
      isBookmarked: boolean,
      type: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VContentByVFaceQueryVariables = {
  VFaceID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VContentByVFaceQuery = {
  vContentByVFace?:  {
    __typename: "ModelVContentConnection",
    items:  Array< {
      __typename: "VContent",
      id: string,
      userID: string,
      VFaceID: string,
      fileName: string,
      storage: string,
      isBookmarked: boolean,
      type: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    company: string,
    field: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    company: string,
    field: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    company: string,
    field: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVFaceSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateVFaceSubscription = {
  onCreateVFace?:  {
    __typename: "VFace",
    id: string,
    userID: string,
    name: string,
    image: string,
    gender: string,
    isBookmarked: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVFaceSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateVFaceSubscription = {
  onUpdateVFace?:  {
    __typename: "VFace",
    id: string,
    userID: string,
    name: string,
    image: string,
    gender: string,
    isBookmarked: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVFaceSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteVFaceSubscription = {
  onDeleteVFace?:  {
    __typename: "VFace",
    id: string,
    userID: string,
    name: string,
    image: string,
    gender: string,
    isBookmarked: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVContentSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateVContentSubscription = {
  onCreateVContent?:  {
    __typename: "VContent",
    id: string,
    userID: string,
    VFaceID: string,
    fileName: string,
    storage: string,
    isBookmarked: boolean,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVContentSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateVContentSubscription = {
  onUpdateVContent?:  {
    __typename: "VContent",
    id: string,
    userID: string,
    VFaceID: string,
    fileName: string,
    storage: string,
    isBookmarked: boolean,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVContentSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteVContentSubscription = {
  onDeleteVContent?:  {
    __typename: "VContent",
    id: string,
    userID: string,
    VFaceID: string,
    fileName: string,
    storage: string,
    isBookmarked: boolean,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVContentComplainSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateVContentComplainSubscription = {
  onCreateVContentComplain?:  {
    __typename: "VContentComplain",
    id: string,
    userID: string,
    VFaceID: string,
    VContentID: string,
    description: string,
    email: string,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVContentComplainSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateVContentComplainSubscription = {
  onUpdateVContentComplain?:  {
    __typename: "VContentComplain",
    id: string,
    userID: string,
    VFaceID: string,
    VContentID: string,
    description: string,
    email: string,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVContentComplainSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteVContentComplainSubscription = {
  onDeleteVContentComplain?:  {
    __typename: "VContentComplain",
    id: string,
    userID: string,
    VFaceID: string,
    VContentID: string,
    description: string,
    email: string,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRequestVFaceSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateRequestVFaceSubscription = {
  onCreateRequestVFace?:  {
    __typename: "RequestVFace",
    id: string,
    userID: string,
    email: string,
    faceDesc: string,
    reference: Array< string | null >,
    testContents: Array< string | null >,
    who: string,
    opinion?: string | null,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRequestVFaceSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateRequestVFaceSubscription = {
  onUpdateRequestVFace?:  {
    __typename: "RequestVFace",
    id: string,
    userID: string,
    email: string,
    faceDesc: string,
    reference: Array< string | null >,
    testContents: Array< string | null >,
    who: string,
    opinion?: string | null,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRequestVFaceSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteRequestVFaceSubscription = {
  onDeleteRequestVFace?:  {
    __typename: "RequestVFace",
    id: string,
    userID: string,
    email: string,
    faceDesc: string,
    reference: Array< string | null >,
    testContents: Array< string | null >,
    who: string,
    opinion?: string | null,
    isFinished: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};
