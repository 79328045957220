import { Box, Button, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import colors from "src/colors";
import logo from "src/icon/logo.svg";
import ChainIcon from "src/icon/icon_chain.svg";
import RoundDot from "src/images/round_dot.png";
import { VFaceSlider } from "src/components/VFaceSlider";

type authProp = {
  setState: Dispatch<SetStateAction<string>>;
};

export default function InfoScreen(props: authProp) {
  const { setState } = props;
  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        background: colors.secondary.main,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "1300px", my: "40px" }}
      >
        <img
          src={logo}
          alt=""
          style={{ width: 199, height: 61, marginLeft: "-40px" }}
        />
        <Typography
          sx={{
            color: "white",
            fontSize: 20,
            fontWeight: 700,
            ml: "-20px",
            mb: "2px",
          }}
        >
          For Business
        </Typography>
      </Stack>
      <VFaceSlider />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "1300px", position: "relative", mt: "73px" }}
      >
        <Stack
          sx={{
            position: "absolute",
            top: "-123px",
            width: "1300px",
            alignItems: "center",
          }}
        >
          <img
            src={RoundDot}
            alt=""
            style={{ width: "330px", height: "330px" }}
          />
          <img
            src={ChainIcon}
            alt=""
            style={{ width: 45, height: 45, marginTop: "-195px" }}
          />
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "130px",
            height: "36px",
            bgcolor: "rgba(0,0,0,0.4)",
            border: "2px solid #F15822",
            position: "absolute",
            bottom: "17px",
            left: "235px",
            zIndex: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              color: "white",
              letterSpacing: "-0.04em",
              fontWeight: 500,
            }}
          >
            Original
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "130px",
            height: "36px",
            bgcolor: "rgba(0,0,0,0.4)",
            border: "2px solid #F15822",
            position: "absolute",
            bottom: "17px",
            right: "235px",
            zIndex: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              color: "white",
              letterSpacing: "-0.04em",
              fontWeight: 500,
            }}
          >
            Result
          </Typography>
        </Stack>
        <video
          loop
          autoPlay
          muted
          src={"/videos/sample_formal.qt"}
          style={{ width: 600, height: 338, zIndex: 2 }}
        />
        <video
          loop
          autoPlay
          muted
          src={"/videos/sample_after.mp4"}
          style={{ width: 600, height: 338, zIndex: 2 }}
        />
      </Stack>
      <Box height="74px" />
      <Button
        onClick={() => setState("signIn")}
        variant="contained"
        sx={{
          width: "423px",
          height: "58px",
          mb: "14px",
          borderRadius: "10px",
        }}
      >
        <Typography
          sx={{
            textTransform: "none",
            fontSize: 20,
            fontWeight: 700,
            letterSpacing: "-0.04em",
          }}
        >
          Make Virtual Human
        </Typography>
      </Button>
      <Typography
        sx={{
          fontSize: 18,
          lineHeight: "32px",
          letterSpacing: "-0.04em",
          color: "white",
          opacity: 0.6,
        }}
      >
        누구나 쉽고 빠르게 버추얼 휴먼을 제작할 수 있습니다!
      </Typography>
    </div>
  );
}
