import React from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
const LoadingWhenResult = (props: { progress: number }) => {
  const { progress } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(34, 34, 34, 0.6)",
        zIndex: 10,
      }}
    >
      <Stack sx={{ width: 378, height: 378, position: "relative" }}>
        <CircularProgress
          size={378}
          variant="determinate"
          sx={{ zIndex: 1 }}
          color="primary"
          value={progress}
          thickness={0.8}
        />
        <CircularProgress
          size={378}
          variant="determinate"
          value={100}
          sx={{
            color: "#898989",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          thickness={0.8}
        />
        <Stack
          sx={{
            width: 378,
            height: 378,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Stack direction="row" alignItems="flex-end" sx={{ mb: "22px" }}>
            <Typography
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: 50,
                lineHeight: "39px",
              }}
            >
              {Math.round(progress)}
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: 25,
                lineHeight: "20px",
              }}
            >
              %
            </Typography>
          </Stack>
          <Typography
            sx={{ color: "white", fontSize: 16, textAlign: "center" }}
          >
            컨텐츠를 변환중입니다
            <br />
            잠시만 기다려주세요
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
};
export default LoadingWhenResult;
