import {
  Box,
  Button,
  Stack,
  Typography,
  Pagination,
  IconButton,
  Modal,
} from "@mui/material";
import React, {
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from "react";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { Auth, Storage } from "aws-amplify";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import BookmarkIcon from "src/icon/icon_book.svg";
import RemoveIcon from "src/icon/icon_delete_white.svg";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
//bookmark on off icon
import BookOn from "src/icon/bookmark/icon_bookmark_orange.svg";
import BookOff from "src/icon/bookmark/icon_bookmark_outline.svg";
import PersonIcon from "@mui/icons-material/Person";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import colors from "../colors";
import NavBar from "../components/NavBar";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import { FliptionContext } from "src/context/Store";
import { VContentWithImage, VFaceWithImage } from "src/types/FileType";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircle from "src/icon/icon_check_circle.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from "moment";
import {
  createVContentComplain,
  CreateVContentComplainInput,
  CreateVContentComplainMutation,
  deleteVContent,
  DeleteVContentInput,
  DeleteVContentMutation,
  updateVContent,
  UpdateVContentInput,
  UpdateVContentMutation,
  updateVFace,
  UpdateVFaceInput,
  UpdateVFaceMutation,
  vContentByUser,
  VContentByUserQuery,
  VContentByUserQueryVariables,
} from "src/graphql";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sortVContent } from "src/functions/Sort";
import LoadingAbsolute from "src/components/LoadingAbsolute";
import Loading from "src/components/Loading";

export default function Vcontent() {
  const [sortFilter, setSortFilter] = useState("recent");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  //dialog step
  const [step, setStep] = useState(0);
  const { user, vfaces, toggleBook } = useContext(FliptionContext);
  const itemsPerPage = 10;
  const [vcontents, setVcontents] = useState<VContentWithImage[] | null>(null);
  //next token
  const [nextToken, setNextToken] = useState<string | null>(null);
  //bookmark 된것만 보여주기
  const [isShowBook, setIsShowBook] = useState(false);
  //가상얼굴인지
  const [isVface, setIsVface] = useState(false);
  //가상얼굴 -> 선택시
  const [selectedVface, setSelectedVface] = useState<VFaceWithImage | null>(
    null
  );
  //detail modal open
  const [open, setOpen] = useState(false);
  const [currentCon, setCurrentCon] = useState<VContentWithImage | null>(null);
  //기간별
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  //재변환
  const [reason, setReason] = useState("");
  const [email, setEmail] = useState("");
  //calendar 버튼
  const CustomInput = forwardRef(
    (
      {
        value,
        onClick,
      }: { value: string; onClick: MouseEventHandler<HTMLButtonElement> },
      ref: ForwardedRef<HTMLButtonElement>
    ) => (
      <Button
        sx={{ flexDirection: "row", height: "35px" }}
        onClick={onClick}
        ref={ref}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 22,
            lineHeight: "32px",
            color: "white",
            letterSpacing: "-0.04em",
            mr: "7px",
          }}
        >
          {value}
        </Typography>
        <CalendarTodayIcon sx={{ color: "white", fontSize: 16 }} />
      </Button>
    )
  );
  function downloadResult(result: VContentWithImage) {
    const resultURL = URL.createObjectURL(result.blob);
    const tempLink = document.createElement("a");
    tempLink.href = resultURL;
    tempLink.setAttribute("download", result.fileName);
    tempLink.click();
  }
  useEffect(() => {
    if (user) {
      fetchVContents(user.id, null);
    }
  }, [user]);
  useEffect(() => {
    if (sortFilter === "recent") {
      setIsVface(false);
      if (vcontents) {
        let newData = [...vcontents];
        newData = sortVContent(newData);
        setVcontents(newData);
      }
    } else if (sortFilter === "period") {
      setIsVface(false);
    } else if (sortFilter === "vface") {
      setPage(1);
      setIsVface(true);
    }
  }, [sortFilter]);
  async function fetchVContents(id: string, nextToken: string | null) {
    setIsLoading(true);
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: vContentByUser,
        variables: {
          userID: id,
          sortDirection: "DESC",
          nextToken: nextToken,
        } as VContentByUserQueryVariables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: VContentByUserQuery };
      //console.log(apiData.data.VContentByUser);
      let datas = apiData.data.VContentByUser?.items as VContentWithImage[];
      if (datas && datas.length !== 0) {
        const promises = datas.map(async (pos) => {
          if (pos && pos.storage) {
            const image = await Storage.get(pos.storage).catch((e) =>
              console.log(e)
            );
            const imageRequest = await Storage.get(pos.storage, {
              download: true,
              expires: 3600,
            }).catch((e) => console.log(e));
            if (image) {
              pos.source = image;
            }
            if (imageRequest) {
              pos.blob = imageRequest.Body as Blob;
            }
          }
          return pos;
        });
        await Promise.all(promises!);
        setVcontents((v) => (v ? sortVContent(v.concat(datas)) : datas));
      }
      if (apiData.data.VContentByUser?.nextToken) {
        fetchVContents(id, apiData.data.VContentByUser?.nextToken);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  }
  const toggleBookmarkFace = (book: boolean, vid: string) => {
    toggleBook(book, vid);
    toggleVfaceBook(book, vid);
  };
  async function toggleVfaceBook(book: boolean, vid: string) {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: updateVFace,
        variables: {
          input: {
            id: vid,
            isBookmarked: book,
          } as UpdateVFaceInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: UpdateVFaceMutation };
      console.log(apiData);
    } catch (e) {
      console.log(e);
    }
  }
  const toggleBookmark = (book: boolean, vid: string) => {
    setVcontents((vc) =>
      vc
        ? vc.map((vcc) =>
            vcc.id === vid ? { ...vcc, isBookmarked: book } : vcc
          )
        : null
    );
    toggleVContent(book, vid);
  };
  async function toggleVContent(book: boolean, vid: string) {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: updateVContent,
        variables: {
          input: {
            id: vid,
            isBookmarked: book,
          } as UpdateVContentInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: UpdateVContentMutation };
      console.log(apiData);
    } catch (e) {
      console.log(e);
    }
  }
  async function removeVContent(vid: string, key: string) {
    setOpen(false);
    setVcontents((v) => (v ? v.filter((vv) => vv.id !== vid) : null));
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: deleteVContent,
        variables: {
          input: {
            id: vid,
          } as DeleteVContentInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: DeleteVContentMutation };
      const apiData2 = await Storage.remove(key);
      console.log(apiData2);
    } catch (e) {
      console.log(e);
    }
  }
  async function makeComplain() {
    setIsLoading(true);
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: createVContentComplain,
        variables: {
          input: {
            userID: user?.id,
            VFaceID: vfaces?.filter(
              (face) => face.id === currentCon?.VFaceID
            )[0]?.id,
            VContentID: currentCon?.id,
            description: reason,
            email: email,
            isFinished: false,
          } as CreateVContentComplainInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: CreateVContentComplainMutation };
      if (apiData.data.createVContentComplain) {
        console.log(apiData.data.createVContentComplain);
        setIsLoading(false);
        setStep(2);
        setReason("");
        setEmail("");
      }
    } catch (e) {
      setIsLoading(false);
      alert("재변환 요청에 실패했습니다.");
      console.log(e);
    }
  }
  return (
    <div
      style={{
        backgroundColor: colors.secondary.main,
        alignItems: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "90px",
      }}
    >
      {isLoading && <Loading />}
      <Modal open={open}>
        <Stack
          sx={{
            width: "964px",
            height: "658px",
            backgroundColor: "white",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px",
          }}
        >
          {isLoading && <LoadingAbsolute />}
          <IconButton
            disabled={isLoading}
            onClick={() => {
              setStep(0);
              setOpen(false);
              setReason("");
              setEmail("");
            }}
            sx={{ position: "absolute", right: -60, top: 0 }}
          >
            <CloseIcon sx={{ color: "white", fontSize: 40 }} />
          </IconButton>
          {step === 0 ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ pt: "34px", pl: "65px", pr: "71px", width: "100%" }}
            >
              <Stack direction="column">
                {currentCon?.type === "image" ? (
                  <img
                    src={currentCon?.source}
                    alt=""
                    style={{ width: 532, height: 550, borderRadius: 10 }}
                  />
                ) : (
                  <video
                    src={currentCon?.source}
                    playsInline
                    controls
                    width={532}
                    height={550}
                    style={{ borderRadius: 10 }}
                  />
                )}
                <Box height="10px" />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center">
                    <IconButton
                      onClick={() =>
                        toggleBookmark(
                          !currentCon?.isBookmarked,
                          currentCon?.id!
                        )
                      }
                      sx={{ mt: "2px", mr: "2px" }}
                    >
                      {currentCon?.isBookmarked ? (
                        <img
                          src={BookOn}
                          alt=""
                          style={{ width: 15, height: 18.44 }}
                        />
                      ) : (
                        <img
                          src={BookOff}
                          alt=""
                          style={{ width: 15, height: 18.44 }}
                        />
                      )}
                    </IconButton>
                    <Typography
                      sx={{
                        color: colors.secondary.light,
                        fontWeight: 700,
                        lineHeight: "16px",
                        letterSpacing: "-0.04em",
                      }}
                    >
                      {currentCon?.fileName}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      color: "#979797",
                      fontSize: 12,
                      letterSpacing: "-0.04em",
                      lineHeight: "16px",
                    }}
                  >
                    {moment(new Date(currentCon?.createdAt!)).format(
                      "YYYY.MM.DD"
                    )}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <img
                  src={
                    vfaces?.filter((face) => face.id === currentCon?.VFaceID)[0]
                      ?.source
                  }
                  alt=""
                  style={{ width: 244, height: 236, borderRadius: 10 }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    color: colors.secondary.light,
                    fontWeight: 700,
                    letterSpacing: "-0.04em",
                    lineHeight: "16px",
                    mt: "14px",
                  }}
                >
                  {
                    vfaces?.filter((face) => face.id === currentCon?.VFaceID)[0]
                      ?.name
                  }
                </Typography>
                <Box height="113px" />
                <Button
                  onClick={() => downloadResult(currentCon!)}
                  variant="outlined"
                  sx={{
                    flexDirection: "row",
                    width: "100%",
                    height: "46px",
                    borderRadius: "5px",
                    borderColor: colors.secondary.light,
                  }}
                >
                  <SaveAltOutlinedIcon
                    sx={{ color: colors.secondary.light, fontSize: 18 }}
                  />
                  <Typography
                    sx={{
                      color: colors.secondary.light,
                      fontSize: 15,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                      ml: "5px",
                    }}
                  >
                    DOWNLOAD
                  </Typography>
                </Button>
                <Box height="16px" />
                <Button
                  onClick={() => setStep(1)}
                  variant="outlined"
                  sx={{
                    flexDirection: "row",
                    width: "100%",
                    height: "46px",
                    borderRadius: "5px",
                    borderColor: colors.secondary.light,
                  }}
                >
                  <RefreshOutlinedIcon
                    sx={{ color: colors.secondary.light, fontSize: 20 }}
                  />
                  <Typography
                    sx={{
                      color: colors.secondary.light,
                      fontSize: 15,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                      ml: "5px",
                    }}
                  >
                    REQUEST AGAIN
                  </Typography>
                </Button>
                <Box height="16px" />
                <Button
                  onClick={() =>
                    removeVContent(currentCon?.id!, currentCon?.source!)
                  }
                  sx={{
                    flexDirection: "row",
                    width: "100%",
                    height: "46px",
                    borderRadius: "5px",
                    backgroundColor: colors.secondary.light,
                  }}
                >
                  <img
                    src={RemoveIcon}
                    alt=""
                    style={{ width: 26, height: 26 }}
                  />
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: 15,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                      ml: "1px",
                    }}
                  >
                    DELETE
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          ) : step === 1 ? (
            <Stack
              alignItems="center"
              sx={{ width: "506px", alignSelf: "center" }}
            >
              <Box height="14px" />
              <Typography
                sx={{
                  fontSize: 22,
                  lineHeight: "32px",
                  letterSpacing: "-0.04em",
                  color: colors.secondary.main,
                  fontWeight: 700,
                }}
              >
                Virtual Content Complain
              </Typography>
              <Box height="18px" />
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ backgroundColor: "#EAEAEA", width: "100%", py: "21px" }}
              >
                <Stack sx={{ width: "181px", position: "relative" }}>
                  <Stack
                    sx={{
                      position: "absolute",
                      top: "9px",
                      left: "9px",
                      backgroundColor: "rgba(0, 0, 0, 0.26)",
                      width: "73px",
                      height: "23px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: "white",
                        letterSpacing: "-0.04em",
                      }}
                    >
                      Result
                    </Typography>
                  </Stack>
                  {currentCon?.type === "image" ? (
                    <img
                      src={currentCon?.source}
                      alt=""
                      style={{ width: 181, height: 181, borderRadius: 10 }}
                    />
                  ) : (
                    <video
                      src={currentCon?.source}
                      playsInline
                      controls
                      width={181}
                      height={181}
                      style={{ borderRadius: 10 }}
                    />
                  )}
                  <Box height="14px" />
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: colors.secondary.light,
                      fontSize: 14,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                      lineHeight: "16px",
                    }}
                  >
                    {currentCon?.fileName}
                  </Typography>
                </Stack>
                <Box width="35px" />
                <Stack sx={{ width: "181px", position: "relative" }}>
                  <Stack
                    sx={{
                      position: "absolute",
                      top: "9px",
                      left: "9px",
                      backgroundColor: "rgba(0, 0, 0, 0.26)",
                      width: "73px",
                      height: "23px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: "white",
                        letterSpacing: "-0.04em",
                      }}
                    >
                      V Face
                    </Typography>
                  </Stack>
                  <img
                    src={
                      vfaces?.filter(
                        (face) => face.id === currentCon?.VFaceID
                      )[0]?.source
                    }
                    alt=""
                    style={{ width: 181, height: 181, borderRadius: 10 }}
                  />
                  <Box height="14px" />
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: colors.secondary.light,
                      fontSize: 14,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                      lineHeight: "16px",
                    }}
                  >
                    {
                      vfaces?.filter(
                        (face) => face.id === currentCon?.VFaceID
                      )[0]?.name
                    }
                  </Typography>
                </Stack>
              </Stack>
              <Box height="21px" />
              <Stack direction="row" sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 16,
                    color: colors.text.light,
                    letterSpacing: "-0.04em",
                  }}
                >
                  재변환 요청사항
                </Typography>
              </Stack>
              <Box height="10px" />
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                rows={4}
                placeholder="재변환을 요청하신 이유를 적어주세요."
                style={{
                  resize: "none",
                  outline: "none",
                  border: "1px solid #BABABA",
                  width: "100%",
                  padding: "9px 13px",
                  fontSize: 14,
                  letterSpacing: "-0.04em",
                  fontFamily: "NotoSansKR",
                }}
              />
              <Box height="21px" />
              <Stack direction="row" sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 16,
                    color: colors.text.light,
                    letterSpacing: "-0.04em",
                  }}
                >
                  이메일
                </Typography>
              </Stack>
              <Box height="10px" />
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="해당 이메일로 변환 결과물과 가상 얼굴을 보내드립니다."
                style={{
                  width: "100%",
                  fontSize: 14,
                  letterSpacing: "-0.04em",
                  fontFamily: "NotoSansKR",
                  resize: "none",
                  outline: "none",
                  border: "none",
                  borderBottom: "1px solid #BABABA",
                  paddingBottom: "9px",
                }}
              />
              <Box height="29px" />
              <Stack direction="row" justifyContent="center">
                <Button
                  onClick={() => setStep(0)}
                  variant="outlined"
                  sx={{
                    width: "135px",
                    height: "46px",
                    border: "1px solid #3d3d3d",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                      color: "#3d3d3d",
                    }}
                  >
                    이전
                  </Typography>
                </Button>
                <Box width="14px" />
                <Button
                  onClick={() => makeComplain()}
                  disabled={email === "" || reason === ""}
                  variant="contained"
                  sx={{
                    width: "135px",
                    height: "46px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                      color: "white",
                    }}
                  >
                    다음
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={CheckCircle} alt="" style={{ width: 91, height: 91 }} />
              <Typography
                sx={{
                  fontSize: 26,
                  lineHeight: "16px",
                  letterSpacing: "-0.04em",
                  fontWeight: 700,
                  color: colors.secondary.light,
                  mt: "29px",
                  mb: "21px",
                }}
              >
                재변환 신청이{" "}
                <Typography
                  sx={{
                    display: "inline-block",
                    color: colors.primary.main,
                    fontSize: 26,
                    lineHeight: "16px",
                    letterSpacing: "-0.04em",
                    fontWeight: 700,
                  }}
                >
                  완료
                </Typography>{" "}
                되었습니다.
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: "20px",
                  letterSpacing: "-0.04em",
                  textAlign: "center",
                }}
              >
                영업일 기준 3일 이내에 작성하신 이메일로
                <br />
                변환결과물이 전송됩니다.
              </Typography>
              <Box height="66px" />
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="outlined"
                sx={{
                  mb: "10px",
                  height: "46px",
                  width: "244px",
                  borderColor: colors.secondary.light,
                }}
              >
                <Typography
                  sx={{
                    color: colors.secondary.light,
                    fontSize: 14,
                    letterSpacing: "-0.04em",
                    fontWeight: 700,
                  }}
                >
                  이전 페이지
                </Typography>
              </Button>
            </Stack>
          )}
        </Stack>
      </Modal>
      <NavBar />
      <Box height="35px" />
      <Stack
        sx={{
          width: "1300px",
          height: "888px",
          alignItems: "center",
          backgroundColor: colors.secondary.light,
          borderRadius: "51px",
          pt: "48px",
          px: "38px",
          position: "relative",
        }}
      >
        <Stack
          direction="row"
          px="21px"
          sx={{ width: "100%", justifyContent: "space-between" }}
        >
          <Stack direction="row">
            {(sortFilter === "vface" || sortFilter === "period") && (
              <Button
                onClick={() => {
                  if (isVface && sortFilter === "vface") {
                    setSelectedVface(null);
                    setSortFilter("recent");
                  } else if (!isVface && sortFilter === "vface") {
                    setSelectedVface(null);
                    setIsVface(true);
                  } else {
                    setSelectedVface(null);
                    setSortFilter("recent");
                  }
                }}
                sx={{
                  border: "1px solid #666666",
                  width: "35px",
                  height: "35px",
                  borderRadius: "5px",
                  p: 0,
                  minWidth: 0,
                  mr: "16px",
                }}
              >
                <KeyboardBackspaceIcon sx={{ color: "white" }} />
              </Button>
            )}
            <Button
              onClick={() => setIsShowBook(!isShowBook)}
              sx={{
                border: "1px solid #666666",
                width: "35px",
                height: "35px",
                borderRadius: "5px",
                backgroundColor: isShowBook
                  ? colors.primary.main
                  : "transparent",
                p: 0,
                minWidth: 0,
              }}
            >
              <img
                src={BookmarkIcon}
                alt=""
                style={{ width: 15, height: 17.6 }}
              />
            </Button>
            {sortFilter === "period" && (
              <Stack direction="row" alignItems="center">
                <DatePicker
                  selected={startDate}
                  maxDate={endDate}
                  dateFormat="yyyy.MM.dd"
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <CustomInput
                      value={""}
                      onClick={function (
                        _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  }
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 22,
                    lineHeight: "32px",
                    color: "white",
                    letterSpacing: "-0.04em",
                  }}
                >
                  ~
                </Typography>
                <DatePicker
                  selected={endDate}
                  minDate={startDate}
                  dateFormat="yyyy.MM.dd"
                  onChange={(date) => setEndDate(date)}
                  customInput={
                    <CustomInput
                      value={""}
                      onClick={function (
                        _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  }
                />
              </Stack>
            )}
          </Stack>
          <Stack direction="row">
            <Button
              onClick={() => {
                setSortFilter("recent");
                setSelectedVface(null);
              }}
              sx={{
                flexDirection: "row",
                height: "35px",
                width: "117px",
                backgroundColor:
                  sortFilter === "recent" ? "#666666" : "transparent",
                border: "1px solid #666666",
                borderRadius: "5px",
              }}
            >
              <WatchLaterIcon
                sx={{ color: "white", mr: "8px", fontSize: 16 }}
              />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  letterSpacing: "-0.04em",
                }}
              >
                최신순
              </Typography>
            </Button>
            <Box width="9px" />
            <Button
              onClick={() => setSortFilter("vface")}
              sx={{
                flexDirection: "row",
                height: "35px",
                width: "117px",
                backgroundColor:
                  sortFilter === "vface" ? "#666666" : "transparent",
                border: "1px solid #666666",
                borderRadius: "5px",
              }}
            >
              <PersonIcon sx={{ color: "white", mr: "4px", fontSize: 20 }} />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  letterSpacing: "-0.04em",
                }}
              >
                가상얼굴
              </Typography>
            </Button>
            <Box width="9px" />
            <Button
              onClick={() => {
                setSortFilter("period");
                setSelectedVface(null);
              }}
              sx={{
                flexDirection: "row",
                height: "35px",
                width: "117px",
                backgroundColor:
                  sortFilter === "period" ? "#666666" : "transparent",
                border: "1px solid #666666",
                borderRadius: "5px",
              }}
            >
              <CalendarTodayIcon
                sx={{ color: "white", mr: "10px", fontSize: 16 }}
              />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  letterSpacing: "-0.04em",
                  mr: "4px",
                }}
              >
                기간별
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", mt: "7px", height: "692px", width: "100%" }}
        >
          {!isVface
            ? vcontents
                ?.filter((srt) => (isShowBook ? srt.isBookmarked : srt))
                ?.filter((srt) =>
                  selectedVface ? srt.VFaceID === selectedVface.id : srt
                )
                ?.filter((srt) =>
                  sortFilter === "period"
                    ? startDate!.valueOf() <=
                        new Date(srt?.createdAt!).valueOf() &&
                      new Date(srt?.createdAt!).valueOf() <= endDate!.valueOf()
                    : srt
                )
                ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((vf, i) => (
                  <Stack
                    key={i}
                    sx={{
                      mx: "21px",
                      my: "35px",
                      width: "202px",
                      height: "276px",
                      borderRadius: "10px",
                      border: "1px solid #BABABA",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => toggleBookmark(!vf.isBookmarked, vf.id)}
                      sx={{
                        position: "absolute",
                        top: "7px",
                        left: "7px",
                        zIndex: 3,
                      }}
                    >
                      {vf.isBookmarked ? (
                        <img
                          src={BookOn}
                          alt=""
                          style={{ width: 15, height: 17.76 }}
                        />
                      ) : (
                        <img
                          src={BookOff}
                          alt=""
                          style={{ width: 15, height: 18.44 }}
                        />
                      )}
                    </IconButton>
                    <img
                      src={
                        vfaces?.filter((face) => face.id === vf.VFaceID)[0]
                          ?.source
                      }
                      alt=""
                      style={{
                        width: 60,
                        height: 60,
                        position: "absolute",
                        top: "129px",
                        right: "13px",
                        filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.5))",
                        borderRadius: 30,
                      }}
                    />
                    {vf.type === "image" ? (
                      <img
                        src={vf.source}
                        alt=""
                        style={{
                          width: "200px",
                          height: "203px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      />
                    ) : (
                      <video
                        src={vf.source}
                        playsInline
                        controls
                        width={200}
                        height={203}
                        style={{
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      />
                    )}
                    <Stack
                      onClick={() => {
                        setCurrentCon(vf);
                        setOpen(true);
                      }}
                      sx={{ width: "200px", height: "73px", cursor: "pointer" }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ px: "16px" }}
                      >
                        <Typography
                          sx={{
                            mt: "10px",
                            mb: "9px",
                            color: colors.text.light,
                            fontWeight: 700,
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                            letterSpacing: "-0.04em",
                          }}
                        >
                          {vf.fileName}
                        </Typography>
                      </Stack>
                      <Typography
                        sx={{
                          color: colors.text.light,
                          fontSize: 12,
                          lineHeight: "16px",
                          letterSpacing: "-0.04em",
                          textAlign: "center",
                        }}
                      >
                        {moment(new Date(vf.createdAt)).format("YYYY.MM.DD")}
                      </Typography>
                    </Stack>
                  </Stack>
                ))
            : vfaces
                ?.filter((srt) => (isShowBook ? srt.isBookmarked : srt))
                ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((vf, i) => (
                  <Stack
                    key={i}
                    sx={{
                      mx: "21px",
                      my: "35px",
                      width: "202px",
                      height: "276px",
                      borderRadius: "10px",
                      border: "1px solid #BABABA",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        toggleBookmarkFace(!vf.isBookmarked, vf.id)
                      }
                      sx={{
                        position: "absolute",
                        top: "7px",
                        left: "7px",
                        zIndex: 3,
                      }}
                    >
                      {vf.isBookmarked ? (
                        <img
                          src={BookOn}
                          alt=""
                          style={{ width: 15, height: 17.76 }}
                        />
                      ) : (
                        <img
                          src={BookOff}
                          alt=""
                          style={{ width: 15, height: 18.44 }}
                        />
                      )}
                    </IconButton>
                    <Stack
                      onClick={() => {
                        setSelectedVface(vf);
                        setIsVface(false);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <img
                        src={vf.source}
                        alt=""
                        style={{
                          width: "200px",
                          height: "203px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: "14px",
                          mb: "9px",
                          color: colors.text.light,
                          lineHeight: "16px",
                          fontWeight: 700,
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          letterSpacing: "-0.04em",
                        }}
                      >
                        {vf.name}
                      </Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <PersonIcon
                          sx={{ color: colors.text.light, fontSize: 18 }}
                        />
                        <Typography
                          sx={{
                            color: colors.text.light,
                            minWidth: "46px",
                            ml: "2px",
                            fontSize: 12,
                            lineHeight: "16px",
                            letterSpacing: "-0.04em",
                          }}
                        >
                          {vf.gender === "male" ? "Man" : "Woman"}
                        </Typography>
                        <WatchLaterIcon
                          sx={{ color: colors.text.light, fontSize: 18 }}
                        />
                        <Typography
                          sx={{
                            color: colors.text.light,
                            ml: "4px",
                            fontSize: 12,
                            lineHeight: "16px",
                            letterSpacing: "-0.04em",
                          }}
                        >
                          {moment(new Date(vf.createdAt)).format("YYYY.MM.DD")}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
        </Stack>
        <Box height="20px" />
        {!isVface && vcontents && (
          <Pagination
            color="primary"
            onChange={(_e, value) => setPage(value)}
            count={Math.ceil(
              vcontents
                .filter((srt) => (isShowBook ? srt.isBookmarked : srt))
                .filter((srt) =>
                  selectedVface ? srt.VFaceID === selectedVface.id : srt
                )
                .filter((srt) =>
                  sortFilter === "period"
                    ? startDate!.valueOf() <=
                        new Date(srt?.createdAt!).valueOf() &&
                      new Date(srt?.createdAt!).valueOf() <= endDate!.valueOf()
                    : srt
                ).length / 10
            )}
          />
        )}
        {isVface && vfaces && (
          <Pagination
            color="primary"
            onChange={(_e, value) => setPage(value)}
            count={Math.ceil(
              vfaces.filter((srt) => (isShowBook ? srt.isBookmarked : srt))
                .length / 10
            )}
          />
        )}
      </Stack>
    </div>
  );
}
