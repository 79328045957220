import React, { useContext, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import logo from "src/icon/logo.svg";
import profile from "src/icon/profile.svg";
import { useLocation } from "react-router-dom";
import colors from "../colors";
import { FliptionContext } from "src/context/Store";

type NavProps = {
  path: string;
  name: string;
};

const NavItem = (props: NavProps) => {
  const { path, name } = props;
  const location = useLocation();
  return (
    <NavLink
      to={path}
      style={{
        textDecoration: "none",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {((location.pathname === "/" && name === "Swap") ||
        (name !== "Swap" && location.pathname.includes(path))) && (
        <Box
          sx={{
            width: "7px",
            height: "7px",
            borderRadius: "3.5px",
            backgroundColor: colors.primary.main,
            position: "absolute",
            left: "-14px",
            top: "9px",
          }}
        />
      )}
      <Typography
        sx={{
          color: "white",
          fontWeight: "bold",
          letterSpacing: "-0.04em",
          transform:
            name === "Swap"
              ? "matrix(0.98, 0, -0.2, 1, 0, 0)"
              : name === "Virtual Face"
              ? "matrix(1, 0, -0.02, 1, 0, 0)"
              : undefined,
        }}
      >
        {name}
      </Typography>
    </NavLink>
  );
};

const NavBar = () => {
  const { user } = useContext(FliptionContext);
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: 1300,
        margin: "0px auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img src={logo} alt="" style={{ width: 199, height: 61 }} />
        <Box width="32px" />
        <NavItem path="/" name="Swap" />
        <Box width="64px" />
        <NavItem path="/virtual" name="Virtual Face" />
        <Box width="64px" />
        <NavItem path="/contents" name="V Content" />
        <Box width="64px" />
        <NavItem path="/request" name="Request V Face" />
      </div>
      <Button
        onClick={() => navigate("/mypage")}
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography sx={{ color: colors.text.light }}>안녕하세요,</Typography>
        <Typography
          sx={{
            color: colors.text.main,
            fontWeight: 500,
            fontSize: 20,
            mx: "6px",
          }}
        >
          {user?.company}
        </Typography>
        <Typography sx={{ color: colors.text.light }}>님</Typography>
        <Box width="15px" />
        <Box
          sx={{
            width: "27px",
            height: "27px",
            borderRadius: "13.5px",
            backgroundColor: "#6D6D6D",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={profile}
            alt=""
            style={{ width: "15px", height: "16.8px" }}
          />
        </Box>
      </Button>
    </div>
  );
};
export default NavBar;
