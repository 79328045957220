import { Box, Button, Stack, Typography, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import colors from "../colors";
import NavBar from "../components/NavBar";
import logoBack from "src/images/fliption_logo_back.png";
import { useNavigate } from "react-router-dom";
import { FliptionContext } from "src/context/Store";
import { Auth } from "aws-amplify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Loading from "src/components/Loading";

export default function ChangePassword() {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(FliptionContext);
  const [isEyeOn1, setIsEyeOn1] = useState(false);
  const [isEyeOn2, setIsEyeOn2] = useState(false);
  const [isEyeOn3, setIsEyeOn3] = useState(false);
  const [pw, setPw] = useState("");
  const [newPw1, setNewPw1] = useState("");
  const [newPw2, setNewPw2] = useState("");
  //errors
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);

  useEffect(() => {
    if (newPw2 !== newPw1) {
      setError3(true);
    } else {
      setError3(false);
    }
  }, [newPw2, newPw1]);

  async function updatePassword() {
    setError1(false);
    setError2(false);
    setError3(false);
    setIsLoading(true);
    const data = {
      id: user?.id,
      password: pw,
      new_password: newPw1,
    };
    await axios
      .post(
        "https://gkcwmo1d9c.execute-api.ap-northeast-2.amazonaws.com/dev/user/password",
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data.success === true) {
          setIsLoading(false);
          alert("비밀번호 변경에 성공했습니다. 다시 로그인 해주세요.");
          Auth.signOut();
        } else {
          if (res.data.isUser === true) {
            setIsLoading(false);
            setError1(true);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setError2(true);
      });
  }
  return (
    <div
      style={{
        backgroundColor: colors.secondary.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${logoBack})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        paddingBottom: "183px",
      }}
    >
      {isLoading && <Loading />}
      <NavBar />
      <Box height="140px" />
      <Stack
        sx={{
          backgroundColor: colors.secondary.light,
          width: "626px",
          pt: "32px",
          pb: "56px",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontSize: 22,
            fontWeight: 700,
            letterSpacing: "-0.04em",
            color: "white",
          }}
        >
          MYPAGE
        </Typography>
        <Typography
          sx={{
            color: colors.text.light,
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: "-0.04em",
            mt: "-5px",
          }}
        >
          비밀번호 변경
        </Typography>
        <Box height="41px" />
        <Stack sx={{ width: "358px" }}>
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            이전 비밀번호
          </Typography>
          <Box height="13px" />
          <Stack direction="row" sx={{ position: "relative" }}>
            <input
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              placeholder="이전 비밀번호를 입력해주세요."
              type={isEyeOn1 ? "text" : "password"}
              style={{
                width: "100%",
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                fontSize: 16,
                color: colors.text.main,
                marginBottom: 6,
              }}
            />
            <IconButton
              onClick={() => setIsEyeOn1(!isEyeOn1)}
              sx={{ position: "absolute", right: 0, top: 0, p: 0 }}
            >
              {isEyeOn1 ? (
                <VisibilityOffIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              ) : (
                <VisibilityIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              )}
            </IconButton>
          </Stack>
          <Stack
            mt="6px"
            mb="44px"
            direction="row"
            sx={{ position: "relative" }}
          >
            <Box
              height="2px"
              width="100%"
              bgcolor={error1 ? colors.error : "#747474"}
            />
            {error1 && (
              <Typography
                sx={{
                  color: colors.error,
                  position: "absolute",
                  top: 6,
                  right: 0,
                  letterSpacing: "-0.04em",
                }}
              >
                이전 비밀번호와 다릅니다.
              </Typography>
            )}
          </Stack>
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            새 비밀번호
          </Typography>
          <Box height="13px" />
          <Stack direction="row" sx={{ position: "relative" }}>
            <input
              value={newPw1}
              onChange={(e) => setNewPw1(e.target.value)}
              placeholder="새로운 비밀번호를 입력해주세요."
              type={isEyeOn2 ? "text" : "password"}
              style={{
                width: "100%",
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                fontSize: 16,
                color: colors.text.main,
                marginBottom: 6,
              }}
            />
            <IconButton
              onClick={() => setIsEyeOn2(!isEyeOn2)}
              sx={{ position: "absolute", right: 0, top: 0, p: 0 }}
            >
              {isEyeOn2 ? (
                <VisibilityOffIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              ) : (
                <VisibilityIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              )}
            </IconButton>
          </Stack>
          <Stack
            mt="6px"
            mb="44px"
            direction="row"
            sx={{ position: "relative" }}
          >
            <Box
              height="2px"
              width="100%"
              bgcolor={error2 ? colors.error : "#747474"}
            />
            {error2 && (
              <Typography
                sx={{
                  color: colors.error,
                  position: "absolute",
                  top: 6,
                  right: 0,
                  letterSpacing: "-0.04em",
                }}
              >
                비밀번호 형식을 맞춰주세요.(8자 이상 특수기호 포함)
              </Typography>
            )}
          </Stack>
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            새 비밀번호 확인
          </Typography>
          <Box height="13px" />
          <Stack direction="row" sx={{ position: "relative" }}>
            <input
              value={newPw2}
              onChange={(e) => setNewPw2(e.target.value)}
              placeholder="새로운 비밀번호를 한 번 더 입력해주세요."
              type={isEyeOn3 ? "text" : "password"}
              style={{
                width: "100%",
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                fontSize: 16,
                color: colors.text.main,
                marginBottom: 6,
              }}
            />
            <IconButton
              onClick={() => setIsEyeOn3(!isEyeOn3)}
              sx={{ position: "absolute", right: 0, top: 0, p: 0 }}
            >
              {isEyeOn3 ? (
                <VisibilityOffIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              ) : (
                <VisibilityIcon
                  sx={{ color: colors.text.light, fontSize: 20 }}
                />
              )}
            </IconButton>
          </Stack>
          <Stack
            mt="6px"
            mb="88px"
            direction="row"
            sx={{ position: "relative" }}
          >
            <Box
              height="2px"
              width="100%"
              bgcolor={error3 ? colors.error : "#747474"}
            />
            {error3 && (
              <Typography
                sx={{
                  color: colors.error,
                  position: "absolute",
                  top: 6,
                  right: 0,
                  letterSpacing: "-0.04em",
                }}
              >
                비밀번호가 일치하지 않습니다.
              </Typography>
            )}
          </Stack>
        </Stack>
        <Button
          disabled={
            pw === "" || newPw1 === "" || newPw2 === "" || newPw1 !== newPw2
          }
          onClick={() => updatePassword()}
          variant="contained"
          color="primary"
          sx={{ width: "358px", height: "47px", borderRadius: "10px" }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: "-0.04em",
            }}
          >
            저장
          </Typography>
        </Button>
        <Box height="22px" />
        <Button
          onClick={() => navigation(-1)}
          sx={{
            width: "358px",
            height: "47px",
            borderRadius: "10px",
            backgroundColor: "#616161",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: "-0.04em",
            }}
          >
            취소
          </Typography>
        </Button>
        <Box height="15px" />
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ width: "358px" }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: 13,
              letterSpacing: "-0.04em",
              cursor: "pointer",
            }}
          >
            회원 탈퇴
          </Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: colors.secondary.main,
          width: "626px",
          py: "5px",
        }}
      >
        <Typography sx={{ color: colors.text.light, letterSpacing: "-0.04em" }}>
          © 2021. Fliption Co. all rights reserved.
        </Typography>
      </Stack>
    </div>
  );
}
