import React, { useState } from "react";
import { Box, Dialog, IconButton, Stack, Typography } from "@mui/material";
import UploadIcon from "src/icon/icon_upload.svg";
import CursorIcon from "src/icon/icon_cursor_orange.svg";
import colors from "src/colors";
import HelpIcon from "@mui/icons-material/Help";
import Ex1 from "src/images/example_img_01.svg";
import Photo1 from "src/images/example_03/001.svg";
import Photo2 from "src/images/example_03/002.svg";
import Photo3 from "src/images/example_03/003.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const Step01 = () => {
  const [isHelp, setIsHelp] = useState(false);
  return (
    <Stack
      direction="row"
      sx={{ width: "1300px", margin: "0px auto", pl: "160px", py: "32px" }}
    >
      <Dialog maxWidth="xl" open={isHelp}>
        <Stack
          sx={{ width: "926px", height: "588px", backgroundColor: "white" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: "55px", py: "19px" }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 12,
                letterSpacing: "-0.04em",
                color: colors.primary.main,
              }}
            >
              System Guide
            </Typography>
            <IconButton onClick={() => setIsHelp(false)} sx={{ m: 0, p: 0 }}>
              <HighlightOffIcon
                sx={{ color: colors.secondary.main, fontSize: 25 }}
              />
            </IconButton>
          </Stack>
          <div
            style={{
              width: "100%",
              height: "488px",
              overflowY: "scroll",
              paddingTop: "15px",
            }}
          >
            <Stack alignItems="center">
              <Box
                sx={{
                  border: "0.5px solid #222222",
                  borderRadius: "12px",
                  mb: "1px",
                  width: "78px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#222222",
                    fontSize: 12,
                    letterSpacing: "-0.04em",
                    lineHeight: "17px",
                  }}
                >
                  STEP 1
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: 22, fontWeight: 700, letterSpacing: "-0.04em" }}
              >
                합성 화면 화질
              </Typography>
              <Box height="21px" />
              <Stack direction="row">
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                    backgroundColor: "rgba(255, 85, 0, 0.3)",
                    fontWeight: 700,
                  }}
                >
                  SD {">"} HD {">"} 4K
                </Typography>
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  순으로 화질이 높은 영상일수록 합성확률이 높아집니다.
                </Typography>
              </Stack>
              <Box height="38px" />
              <Box width="818px" height="1px" bgcolor="#C9C9C9" />
              <Box height="42px" />
              <Box
                sx={{
                  border: "0.5px solid #222222",
                  borderRadius: "12px",
                  mb: "1px",
                  width: "78px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#222222",
                    fontSize: 12,
                    letterSpacing: "-0.04em",
                    lineHeight: "17px",
                  }}
                >
                  STEP 2
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: 22, fontWeight: 700, letterSpacing: "-0.04em" }}
              >
                원본 얼굴 각도
              </Typography>
              <Box height="30px" />
              <img
                src={Ex1}
                alt=""
                style={{ width: "448px", height: "118px" }}
              />
              <Box height="30px" />
              <Stack direction="row">
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  얼굴각도의 경우 최대&nbsp;
                </Typography>
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                    backgroundColor: "rgba(255, 85, 0, 0.3)",
                    fontWeight: 700,
                  }}
                >
                  좌&우 각 75도
                </Typography>
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  &nbsp;씩 총 150도까지 합성이 가능합니다.
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: colors.secondary.main,
                  fontSize: 16,
                  lineHeight: "24px",
                  fontWeight: 700,
                }}
              >
                권고사항 : 좌&우 55도 씩, 총 110도 내에서 얼굴을 움직여주세요.
              </Typography>
              <Box height="37px" />
              <Box width="818px" height="1px" bgcolor="#C9C9C9" />
              <Box height="41px" />
              <Box
                sx={{
                  border: "0.5px solid #222222",
                  borderRadius: "12px",
                  mb: "1px",
                  width: "78px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#222222",
                    fontSize: 12,
                    letterSpacing: "-0.04em",
                    lineHeight: "17px",
                  }}
                >
                  STEP 3
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: 22, fontWeight: 700, letterSpacing: "-0.04em" }}
              >
                얼굴이 차지하는 범위
              </Typography>
              <Box height="36px" />
              <Stack direction="row">
                <Stack sx={{ position: "relative" }}>
                  <img
                    src={Photo1}
                    alt=""
                    style={{ width: 149.82, height: 142.86 }}
                  />
                  <Typography
                    sx={{
                      color: "#222222",
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                    }}
                  >
                    50%
                  </Typography>
                  <Stack
                    sx={{
                      alignItems: "center",
                      position: "absolute",
                      bottom: 30,
                      width: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "26px",
                        height: "26px",
                        borderRadius: "13px",
                        backgroundColor: colors.error2,
                      }}
                    >
                      <CloseIcon sx={{ color: "white", fontSize: 18 }} />
                    </Stack>
                  </Stack>
                </Stack>
                <Box width="35.78px" />
                <Stack sx={{ position: "relative" }}>
                  <img
                    src={Photo2}
                    alt=""
                    style={{ width: 149.82, height: 142.86 }}
                  />
                  <Typography
                    sx={{
                      color: "#222222",
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                    }}
                  >
                    25%
                  </Typography>
                  <Stack
                    sx={{
                      alignItems: "center",
                      position: "absolute",
                      bottom: 30,
                      width: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "26px",
                        height: "26px",
                        borderRadius: "13px",
                        backgroundColor: "#32C000",
                      }}
                    >
                      <CheckIcon sx={{ color: "white", fontSize: 20 }} />
                    </Stack>
                  </Stack>
                </Stack>
                <Box width="35.78px" />
                <Stack sx={{ position: "relative" }}>
                  <img
                    src={Photo3}
                    alt=""
                    style={{ width: 149.82, height: 142.86 }}
                  />
                  <Typography
                    sx={{
                      color: "#222222",
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: 700,
                      letterSpacing: "-0.04em",
                    }}
                  >
                    1%
                  </Typography>
                  <Stack
                    sx={{
                      alignItems: "center",
                      position: "absolute",
                      bottom: 30,
                      width: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "26px",
                        height: "26px",
                        borderRadius: "13px",
                        backgroundColor: colors.error2,
                      }}
                    >
                      <CloseIcon sx={{ color: "white", fontSize: 18 }} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Box height="21px" />
              <Typography
                sx={{
                  color: colors.secondary.main,
                  fontSize: 16,
                  lineHeight: "24px",
                }}
              >
                화면에서 얼굴이 차지하는 범위는
              </Typography>
              <Stack direction="row">
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                    backgroundColor: "rgba(255, 85, 0, 0.3)",
                    fontWeight: 700,
                  }}
                >
                  평균적으로 n%
                </Typography>
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                    fontWeight: 700,
                  }}
                >
                  &nbsp;차지하며 25% 이상
                </Typography>
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  은&nbsp;
                </Typography>
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                    fontWeight: 700,
                  }}
                >
                  실패율
                </Typography>
                <Typography
                  sx={{
                    color: colors.secondary.main,
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  이 높아집니다.
                </Typography>
              </Stack>
              <Box height="30px" />
            </Stack>
          </div>
        </Stack>
      </Dialog>
      <Box
        sx={{
          border: "1px solid #BABABA",
          width: "159px",
          height: "181px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: "49px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#515151",
            width: "141px",
            height: "25px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              color: colors.text.main,
              fontSize: 12,
              mr: "2px",
            }}
          >
            <img src={UploadIcon} alt="" style={{ marginRight: "6.21px" }} />
            File Upload
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: "15px",
              right: "21.86px",
              border: "2px solid #848484",
              width: "11px",
              height: "11px",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                width: "5px",
                height: "5px",
                borderRadius: "2.5px",
                backgroundColor: "#848484",
                margin: "3px auto",
              }}
            />
          </Box>
          <img
            src={CursorIcon}
            alt=""
            style={{
              position: "absolute",
              top: "23px",
              right: "15.86px",
              width: 11,
              height: 11,
            }}
          />
        </Box>
      </Box>
      <Stack sx={{ pt: "11px" }}>
        <Box
          sx={{
            border: "0.5px solid #FFFFFF",
            borderRadius: "12px",
            mb: "4px",
            width: "78px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: 12,
              letterSpacing: "-0.04em",
              lineHeight: "17px",
            }}
          >
            STEP 1
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "white",
            letterSpacing: "-0.04em",
            fontSize: 22,
            fontWeight: 700,
          }}
        >
          파일 업로드
        </Typography>
        <Box height="16px" />
        <Typography sx={{ fontSize: 16, color: "#BABABA" }}>
          변환 하고자 하는 모든 파일(영상, 사진)을 업로드 해주세요.
          <br />
          합성이 잘되는 파일의 구체적인 부분이 궁금하다면 아래{" "}
          <Typography sx={{ fontWeight: 700, display: "inline" }}>
            [자세히보기]
          </Typography>{" "}
          버튼을 눌러 참고하세요.
        </Typography>
        <Stack
          onClick={() => setIsHelp(true)}
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer", mt: "17px" }}
        >
          <HelpIcon sx={{ color: "#A7A7A7", fontSize: 16, mr: "5px" }} />
          <Typography sx={{ color: colors.text.light, fontSize: 12 }}>
            자세히보기
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Step01;
