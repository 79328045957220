import { Box, Button, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import React from "react";
import { useNavigate } from "react-router-dom";
import colors from "src/colors";
import logo from "src/icon/logo.svg";

export default function NoVface() {
  const navigation = useNavigate();
  return (
    <Stack
      sx={{
        backgroundColor: colors.secondary.main,
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <img src={logo} alt="" style={{ width: 587, height: 210 }} />
      <Typography
        sx={{
          color: "white",
          fontSize: 26,
          fontWeight: 700,
          letterSpacing: "-0.04em",
        }}
      >
        You don’t have any Virtual Face
      </Typography>
      <Box height="78px" />
      <Button
        onClick={() => navigation("/request")}
        sx={{
          backgroundColor: colors.primary.main,
          color: colors.text.main,
          height: "47px",
          borderRadius: "10px",
          width: "358px",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: "-0.04em",
          }}
        >
          CONTACT US
        </Typography>
      </Button>
      <Button
        onClick={() => Auth.signOut()}
        sx={{
          color: colors.text.main,
          height: "47px",
          borderRadius: "10px",
          width: "358px",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: "-0.04em",
          }}
        >
          LOG OUT
        </Typography>
      </Button>
      <Box height="90px" />
    </Stack>
  );
}
