import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import colors from "../colors";
import NavBar from "../components/NavBar";
import logoBack from "src/images/fliption_logo_back.png";
import { useNavigate } from "react-router-dom";
import { FliptionContext } from "src/context/Store";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { updateUser, UpdateUserInput, UpdateUserMutation } from "src/graphql";

export default function EditProfile() {
  const navigation = useNavigate();
  const { user, setUser } = useContext(FliptionContext);
  const [company, setCompany] = useState(user?.company);
  const [field, setField] = useState(user?.field);
  async function updateInfo() {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: updateUser,
        variables: {
          input: {
            id: user?.id,
            company,
            field,
          } as UpdateUserInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: UpdateUserMutation };
      console.log(apiData);
      if (apiData.data.updateUser) {
        setUser(apiData.data.updateUser);
        navigation(-1);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div
      style={{
        backgroundColor: colors.secondary.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${logoBack})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        paddingBottom: "183px",
      }}
    >
      <NavBar />
      <Box height="140px" />
      <Stack
        sx={{
          backgroundColor: colors.secondary.light,
          width: "626px",
          pt: "32px",
          pb: "56px",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontSize: 22,
            fontWeight: 700,
            letterSpacing: "-0.04em",
            color: "white",
          }}
        >
          MYPAGE
        </Typography>
        <Typography
          sx={{
            color: colors.text.light,
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: "-0.04em",
            mt: "-5px",
          }}
        >
          기본프로필 수정
        </Typography>
        <Box height="41px" />
        <Stack sx={{ width: "358px" }}>
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            USER ID
          </Typography>
          <Box height="13px" />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{ color: "#747474", fontSize: 16, letterSpacing: "-0.04em" }}
            >
              {user?.id}
            </Typography>
            <Typography
              sx={{ color: "#747474", fontSize: 13, letterSpacing: "-0.04em" }}
            >
              유저 아이디는 수정이 불가능합니다.
            </Typography>
          </Stack>
          <Box width="100%" height="2px" bgcolor="#747474" mt="6px" mb="44px" />
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            PASSWORD
          </Typography>
          <Box height="13px" />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{ color: "#747474", fontSize: 16, letterSpacing: "-0.04em" }}
            >
              ******
            </Typography>
            <Button
              onClick={() => navigation("/password")}
              sx={{
                bgcolor: "#616161",
                width: "98px",
                height: "24px",
                borderRadius: "5px",
                mb: "3px",
              }}
            >
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: "white",
                  letterSpacing: "-0.04em",
                }}
              >
                비밀번호 변경
              </Typography>
            </Button>
          </Stack>
          <Box width="100%" height="2px" bgcolor="#747474" mt="6px" mb="44px" />
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            회사명
          </Typography>
          <Box height="13px" />
          <input
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            type="text"
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              backgroundColor: "transparent",
              color: "white",
              fontFamily: "NotoSansKR",
              fontSize: 16,
            }}
          />
          <Box width="100%" height="2px" bgcolor="#747474" mt="6px" mb="44px" />
          <Typography
            sx={{
              color: colors.text.light,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              lineHeight: "20px",
            }}
          >
            적용 분야
          </Typography>
          <Box height="13px" />
          <input
            value={field}
            onChange={(e) => setField(e.target.value)}
            type="text"
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              backgroundColor: "transparent",
              color: "white",
              fontFamily: "NotoSansKR",
              fontSize: 16,
            }}
          />
          <Box width="100%" height="2px" bgcolor="#747474" mt="6px" mb="88px" />
        </Stack>
        <Button
          disabled={company === "" || field === ""}
          onClick={() => updateInfo()}
          variant="contained"
          color="primary"
          sx={{ width: "358px", height: "47px", borderRadius: "10px" }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: "-0.04em",
            }}
          >
            저장
          </Typography>
        </Button>
        <Box height="22px" />
        <Button
          onClick={() => navigation(-1)}
          sx={{
            width: "358px",
            height: "47px",
            borderRadius: "10px",
            backgroundColor: "#616161",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: "-0.04em",
            }}
          >
            취소
          </Typography>
        </Button>
        <Box height="15px" />
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ width: "358px" }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: 13,
              letterSpacing: "-0.04em",
              cursor: "pointer",
            }}
          >
            회원 탈퇴
          </Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: colors.secondary.main,
          width: "626px",
          py: "5px",
        }}
      >
        <Typography sx={{ color: colors.text.light, letterSpacing: "-0.04em" }}>
          © 2021. Fliption Co. all rights reserved.
        </Typography>
      </Stack>
    </div>
  );
}
