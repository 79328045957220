import React, { useState } from "react";
import InfoScreen from "./InfoScreen";
import Sign from "./Sign";
import SignUp from "./SignUp";

export default function Authenticator() {
  const [state, setState] = useState("info");
  return state === "info" ? (
    <InfoScreen setState={setState} />
  ) : state === "signIn" ? (
    <Sign setState={setState} />
  ) : state === "signUp" ? (
    <SignUp setState={setState} />
  ) : null;
}
