import { createContext, ReactNode, useEffect, useState } from "react";
import {
  getUser,
  GetUserQuery,
  GetUserQueryVariables,
  User,
  VFace,
  vFaceByUser,
  VFaceByUserQuery,
  VFaceByUserQueryVariables,
} from "src/graphql";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { Auth, Storage } from "aws-amplify";
import { VFaceWithImage } from "src/types/FileType";

export const FliptionContext = createContext({
  user: null as User | null,
  vfaces: null as VFaceWithImage[] | null,
  changeInfo: () => {},
  setUser: (_user: User) => {},
  toggleBook: (_book: boolean, _vid: string) => {},
});

const Store: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [vfaces, setVfaces] = useState<VFaceWithImage[] | null>(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((res) => {
      fetchUser(res.username);
      fetchVfaces(res.username);
    });
  }, []);
  const changeInfo = () => {
    Auth.currentAuthenticatedUser().then((res) => {
      fetchUser(res.username);
      fetchVfaces(res.username);
    });
  };
  const toggleBook = (book: boolean, vid: string) => {
    setVfaces((srt) =>
      srt
        ? srt.map((ss) => (ss.id === vid ? { ...ss, isBookmarked: book } : ss))
        : null
    );
  };
  async function fetchUser(id: string) {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: getUser,
        variables: { id: id } as GetUserQueryVariables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: GetUserQuery };
      //console.log(apiData.data);
      if (apiData.data.getUser) {
        setUser(apiData.data.getUser);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function fetchVfaces(id: string) {
    try {
      const apiData = (await GraphQLAPI.graphql({
        query: vFaceByUser,
        variables: { userID: id } as VFaceByUserQueryVariables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })) as { data: VFaceByUserQuery };
      //console.log(apiData.data);
      let datas = apiData.data.VFaceByUser?.items as VFaceWithImage[];
      if (datas && datas.length !== 0) {
        const promises = datas.map(async (pos) => {
          if (pos && pos.image) {
            const image = await Storage.get(pos.image);
            const imageRequest = await Storage.get(pos.image, {
              download: true,
              expires: 3600,
            });
            pos.source = image;
            pos.blob = imageRequest.Body as Blob;
          }
          return pos;
        });
        await Promise.all(promises!);
        setVfaces(datas);
      } else {
        setVfaces(null);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <FliptionContext.Provider
      value={{ user, changeInfo, vfaces, toggleBook, setUser }}
    >
      {children}
    </FliptionContext.Provider>
  );
};

export default Store;
