import React, { useContext } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import VirtualFace from "./pages/VirtualFace";
import Authenticator from "./pages/auth/Authenticator";
import Vcontent from "./pages/Vcontent";
import RequestVFace from "./pages/RequestVFace";
import MyPage from "./pages/MyPage";
import { FliptionContext } from "./context/Store";
import NoVface from "./pages/NoVface";
import EditProfile from "./pages/EditProfile";
import ChangePassword from "./pages/ChangePassword";
import RequestPublic from "./pages/RequestPublic";

function App() {
  const { vfaces } = useContext(FliptionContext);
  return vfaces && vfaces.length !== 0 ? (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/virtual" element={<VirtualFace />} />
      <Route path="/contents" element={<Vcontent />} />
      <Route path="/request" element={<RequestVFace />} />
      <Route path="/mypage" element={<MyPage />} />
      <Route path="/edit" element={<EditProfile />} />
      <Route path="/password" element={<ChangePassword />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<NoVface />} />
      <Route path="/request" element={<RequestPublic />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default withAuthenticator(App, false, [<Authenticator />], null);
