import {
  Button,
  Collapse,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { filesType } from "src/types/FileType";
import colors from "src/colors";
import "../../node_modules/video-react/dist/video-react.css"; // import css
import { Player } from "video-react";
import ErrorIcon from "@mui/icons-material/Error";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

type fileUploadProp = {
  files: filesType[] | null;
  setFiles: Dispatch<SetStateAction<filesType[] | null>>;
};

const FileUpload = (props: fileUploadProp) => {
  const { files, setFiles } = props;
  const [openInfo, setOpenInfo] = useState(true);
  const fileRef = useRef<HTMLInputElement>(null);
  const fileUploaderAction = (e: ChangeEvent<HTMLInputElement>) => {
    //console.log(e.target.files);
    const currentFiles = e.target.files;
    if (currentFiles && currentFiles.length !== 0) {
      const data = Array.from(currentFiles).map((current) => {
        const result = URL.createObjectURL(
          new Blob([current], { type: current.type })
        );
        //console.log(current);
        const output = {
          file: current,
          preview: result,
          type: current.type.includes("image/") ? "image" : "video",
        } as filesType;
        //console.log(output);
        return output;
      });
      if (data) {
        setFiles((fs) => (fs ? fs.concat(data) : data));
      }
      e.preventDefault();
    }
  };
  function removeCurrentFile(file: filesType) {
    setFiles((fs) => fs && fs.filter((ff) => ff !== file));
  }
  return (
    <Stack
      direction="row"
      sx={{
        maxWidth: "1180px",
        overflowX: "scroll",
        overflowY: "hidden",
        margin: "51px auto 0px auto",
        height: "342px",
        alignItems: "center",
      }}
    >
      <Tooltip title="여러장의 이미지 or 비디오 업로드 가능">
        <Stack
          sx={{
            borderRadius: "20px",
            backgroundColor: "#666666",
            height: "313px",
            alignItems: "center",
            pt: "80px",
            px: "36px",
            position: "relative",
          }}
        >
          <PhotoLibraryOutlinedIcon
            sx={{ fontSize: 70, color: "#9F9F9F", mb: "16px" }}
          />
          <Typography
            sx={{
              fontSize: 14,
              color: "#9F9F9F",
              mb: "60px",
              fontWeight: 500,
              letterSpacing: "-0.04em",
            }}
          >
            Add Photo or Video
          </Typography>
          <Button
            onClick={() => fileRef.current?.click()}
            sx={{
              backgroundColor: "#515151",
              width: "175px",
              borderRadius: "10px",
              height: "31px",
            }}
          >
            <Stack direction="row" alignItems="center">
              <FileUploadOutlinedIcon
                sx={{ color: "white", fontSize: 13, mr: "6.5px" }}
              />
              <Typography
                sx={{
                  fontSize: 13,
                  color: "white",
                  fontWeight: "500",
                  letterSpacing: "-0.04em",
                }}
              >
                File Upload
              </Typography>
            </Stack>
          </Button>
          <input
            onChange={(e) => fileUploaderAction(e)}
            accept="image/*, video/*"
            multiple={true}
            ref={fileRef}
            style={{ display: "none" }}
            type="file"
          />
        </Stack>
      </Tooltip>
      {files && files.length !== 0 && (
        <Stack
          sx={{
            pl: "51px",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row">
            {files
              .filter((_file, i) => i % 2 === 0)
              .map((file, i) => (
                <Stack
                  key={i}
                  sx={{
                    width: "125px",
                    height: "157px",
                    mx: "9px",
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => removeCurrentFile(file)}
                    sx={{
                      p: 0,
                      position: "absolute",
                      top: "8px",
                      right: "9px",
                      zIndex: 3,
                    }}
                  >
                    <RemoveCircleIcon sx={{ color: colors.secondary.light }} />
                  </IconButton>
                  {file.type === "image" ? (
                    <img
                      src={file.preview}
                      alt=""
                      style={{
                        width: 125,
                        height: 133,
                        borderRadius: 20,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <video
                      playsInline
                      src={file.preview}
                      controls
                      style={{ borderRadius: 20, objectFit: "cover" }}
                      width={125}
                      height={133}
                    />
                  )}
                  <Typography
                    sx={{
                      mt: "4px",
                      color: colors.text.light,
                      lineHeight: "20px",
                      fontWeight: 500,
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      letterSpacing: "-0.04em",
                    }}
                  >
                    {file.file.name}
                  </Typography>
                </Stack>
              ))}
          </Stack>
          <Stack direction="row">
            {files
              .filter((_file, i) => i % 2 === 1)
              .map((file, i) => (
                <Stack
                  key={i}
                  sx={{
                    width: "125px",
                    height: "157px",
                    mx: "9px",
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => removeCurrentFile(file)}
                    sx={{
                      p: 0,
                      position: "absolute",
                      top: "8px",
                      right: "9px",
                    }}
                  >
                    <RemoveCircleIcon sx={{ color: colors.secondary.light }} />
                  </IconButton>
                  {file.type === "image" ? (
                    <img
                      src={file.preview}
                      alt=""
                      style={{
                        width: 125,
                        height: 133,
                        borderRadius: 20,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Player
                      playsInline
                      src={file.preview}
                      fluid={false}
                      width={125}
                      height={133}
                    />
                  )}
                  <Typography
                    sx={{
                      mt: "4px",
                      color: colors.text.light,
                      lineHeight: "20px",
                      fontWeight: 500,
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      letterSpacing: "-0.04em",
                    }}
                  >
                    {file.file.name}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default FileUpload;
