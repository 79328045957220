import { VFace } from "src/graphql";
import { VContentWithImage, VFaceWithImage } from "src/types/FileType";

export function sortVFace(src: VFaceWithImage[]) {
  return src.sort(
    (a: VFaceWithImage, b: VFaceWithImage) =>
      new Date(b?.createdAt!).valueOf() - new Date(a?.createdAt!).valueOf()
  ) as VFaceWithImage[];
}
export function sortVFaceByName(src: VFaceWithImage[]) {
  return src.sort((a: VFaceWithImage, b: VFaceWithImage) => {
    if (a.name > b.name) return 1;
    else if (b.name > a.name) return -1;
    else return 0;
  }) as VFaceWithImage[];
}

export function sortVContent(src: VContentWithImage[]) {
  return src.sort(
    (a: VContentWithImage, b: VContentWithImage) =>
      new Date(b?.createdAt!).valueOf() - new Date(a?.createdAt!).valueOf()
  ) as VContentWithImage[];
}
export function sortVContentByName(src: VContentWithImage[]) {
  return src.sort((a: VContentWithImage, b: VContentWithImage) => {
    if (a.fileName > b.fileName) return 1;
    else if (b.fileName > a.fileName) return -1;
    else return 0;
  }) as VContentWithImage[];
}

export function sortVContentByPeriod(
  src: VContentWithImage[],
  start: Date,
  end: Date
) {
  return src.filter(
    (a: VContentWithImage) =>
      start.valueOf() <= new Date(a?.createdAt!).valueOf() &&
      new Date(a?.createdAt!).valueOf() <= end.valueOf()
  ) as VContentWithImage[];
}
