import { createTheme } from "@mui/material/styles";
import colors from "./colors";
import "./index.css";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      light: "rgba(255, 85, 0, 0.6)",
      main: "#FF5500",
      dark: "#FF5500",
    },
    secondary: {
      light: "#5a97fa",
      main: "#222222",
      dark: "#444444",
    },
    error: {
      light: "#FF617D",
      main: "#D55454",
    },
    info: {
      main: "#55FFA3",
    },
    success: {
      main: "#5B71E1",
    },
  },
  typography: {
    fontFamily: "NotoSansKR",
    h1: {
      fontSize: "2rem",
      fontFamily: "NotoSansKR-Bold",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.8rem",
      fontFamily: "NotoSansKR-Bold",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.4rem",
      fontFamily: "NotoSansKR-Bold",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.2rem",
      fontFamily: "NotoSansKR-Bold",
      fontWeight: "bold",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "#222222",
            color: "white",
          },
          ":hover": {
            backgroundColor: "rgba(255, 85, 0, 0.3)",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.primary.main,
        },
      },
    },
  },
});

export default theme;
