/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      company
      field
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      company
      field
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      company
      field
      createdAt
      updatedAt
    }
  }
`;
export const createVFace = /* GraphQL */ `
  mutation CreateVFace(
    $input: CreateVFaceInput!
    $condition: ModelVFaceConditionInput
  ) {
    createVFace(input: $input, condition: $condition) {
      id
      userID
      name
      image
      gender
      isBookmarked
      createdAt
      updatedAt
    }
  }
`;
export const updateVFace = /* GraphQL */ `
  mutation UpdateVFace(
    $input: UpdateVFaceInput!
    $condition: ModelVFaceConditionInput
  ) {
    updateVFace(input: $input, condition: $condition) {
      id
      userID
      name
      image
      gender
      isBookmarked
      createdAt
      updatedAt
    }
  }
`;
export const deleteVFace = /* GraphQL */ `
  mutation DeleteVFace(
    $input: DeleteVFaceInput!
    $condition: ModelVFaceConditionInput
  ) {
    deleteVFace(input: $input, condition: $condition) {
      id
      userID
      name
      image
      gender
      isBookmarked
      createdAt
      updatedAt
    }
  }
`;
export const createVContent = /* GraphQL */ `
  mutation CreateVContent(
    $input: CreateVContentInput!
    $condition: ModelVContentConditionInput
  ) {
    createVContent(input: $input, condition: $condition) {
      id
      userID
      VFaceID
      fileName
      storage
      isBookmarked
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateVContent = /* GraphQL */ `
  mutation UpdateVContent(
    $input: UpdateVContentInput!
    $condition: ModelVContentConditionInput
  ) {
    updateVContent(input: $input, condition: $condition) {
      id
      userID
      VFaceID
      fileName
      storage
      isBookmarked
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteVContent = /* GraphQL */ `
  mutation DeleteVContent(
    $input: DeleteVContentInput!
    $condition: ModelVContentConditionInput
  ) {
    deleteVContent(input: $input, condition: $condition) {
      id
      userID
      VFaceID
      fileName
      storage
      isBookmarked
      type
      createdAt
      updatedAt
    }
  }
`;
export const createVContentComplain = /* GraphQL */ `
  mutation CreateVContentComplain(
    $input: CreateVContentComplainInput!
    $condition: ModelVContentComplainConditionInput
  ) {
    createVContentComplain(input: $input, condition: $condition) {
      id
      userID
      VFaceID
      VContentID
      description
      email
      isFinished
      createdAt
      updatedAt
    }
  }
`;
export const updateVContentComplain = /* GraphQL */ `
  mutation UpdateVContentComplain(
    $input: UpdateVContentComplainInput!
    $condition: ModelVContentComplainConditionInput
  ) {
    updateVContentComplain(input: $input, condition: $condition) {
      id
      userID
      VFaceID
      VContentID
      description
      email
      isFinished
      createdAt
      updatedAt
    }
  }
`;
export const deleteVContentComplain = /* GraphQL */ `
  mutation DeleteVContentComplain(
    $input: DeleteVContentComplainInput!
    $condition: ModelVContentComplainConditionInput
  ) {
    deleteVContentComplain(input: $input, condition: $condition) {
      id
      userID
      VFaceID
      VContentID
      description
      email
      isFinished
      createdAt
      updatedAt
    }
  }
`;
export const createRequestVFace = /* GraphQL */ `
  mutation CreateRequestVFace(
    $input: CreateRequestVFaceInput!
    $condition: ModelRequestVFaceConditionInput
  ) {
    createRequestVFace(input: $input, condition: $condition) {
      id
      userID
      email
      faceDesc
      reference
      testContents
      who
      opinion
      isFinished
      createdAt
      updatedAt
    }
  }
`;
export const updateRequestVFace = /* GraphQL */ `
  mutation UpdateRequestVFace(
    $input: UpdateRequestVFaceInput!
    $condition: ModelRequestVFaceConditionInput
  ) {
    updateRequestVFace(input: $input, condition: $condition) {
      id
      userID
      email
      faceDesc
      reference
      testContents
      who
      opinion
      isFinished
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequestVFace = /* GraphQL */ `
  mutation DeleteRequestVFace(
    $input: DeleteRequestVFaceInput!
    $condition: ModelRequestVFaceConditionInput
  ) {
    deleteRequestVFace(input: $input, condition: $condition) {
      id
      userID
      email
      faceDesc
      reference
      testContents
      who
      opinion
      isFinished
      createdAt
      updatedAt
    }
  }
`;
