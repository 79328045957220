import { Stack, Button, Typography, Box, Tooltip } from "@mui/material";
import React, { useState } from "react";
import colors from "src/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { resultVType } from "src/types/FileType";
import { useNavigate } from "react-router-dom";

type resultProp = {
  results: resultVType[];
  reconvert: () => {};
};

const ResultPage = (props: resultProp) => {
  const { results, reconvert } = props;
  const [tabValue, setTabValue] = useState("전체");
  const navigate = useNavigate();
  function downloadResult(result: resultVType) {
    const resultURL = URL.createObjectURL(result.blob);
    const tempLink = document.createElement("a");
    tempLink.href = resultURL;
    tempLink.setAttribute("download", result.name);
    tempLink.click();
  }
   async function pause(msec: any) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, msec || 1000);
    });
  }
  const download = async () => {
    console.log(results);
    // return;
    const newResults = results?.filter((res) => res.isSuccess === true);
    for (let i = 0; i < newResults.length; i++) {
      downloadResult(newResults[i]);
      if (i >= 9) {
        await pause(1000);
      }
    }
  };
  return (
    <Stack
      sx={{
        width: "1190px",
        px: "18px",
        position: "relative",
        height: "480px",
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <Button
          onClick={() => setTabValue("전체")}
          variant="outlined"
          sx={{
            width: "76px",
            height: "30px",
            border: "1px solid #666666",
            borderRadius: 0,
            backgroundColor: tabValue === "전체" ? "#666666" : "transparent",
          }}
        >
          <Typography
            sx={{ color: "white", fontWeight: 500, letterSpacing: "-0.04em" }}
          >
            전체
          </Typography>
        </Button>
        <Button
          onClick={() => setTabValue("성공")}
          variant="outlined"
          sx={{
            width: "76px",
            height: "30px",
            border: "1px solid #666666",
            borderRadius: 0,
            backgroundColor: tabValue === "성공" ? "#666666" : "transparent",
          }}
        >
          <Typography
            sx={{ color: "white", fontWeight: 500, letterSpacing: "-0.04em" }}
          >
            성공
          </Typography>
        </Button>
        <Button
          onClick={() => setTabValue("실패")}
          variant="outlined"
          sx={{
            width: "76px",
            height: "30px",
            border: "1px solid #666666",
            borderRadius: 0,
            backgroundColor: tabValue === "실패" ? "#666666" : "transparent",
          }}
        >
          <Typography
            sx={{ color: "white", fontWeight: 500, letterSpacing: "-0.04em" }}
          >
            실패
          </Typography>
        </Button>
      </Stack>
      <Stack
        direction="row"
        sx={{
          overflowY: "scroll",
          width: "100%",
          flexWrap: "wrap",
          height: "300px",
        }}
      >
        {results
          .filter((result) =>
            tabValue === "전체"
              ? result
              : tabValue === "성공"
              ? result.isSuccess
              : !result.isSuccess
          )
          .map((res, i) => (
            <Stack
              key={i}
              sx={{
                mx: "9px",
                my: "7px",
                width: "126px",
                position: "relative",
              }}
            >
              {res.isSuccess === true ? (
                <Box
                  sx={{
                    position: "absolute",
                    top: 12,
                    right: 12,
                    width: 25,
                    height: 25,
                    borderRadius: 12.5,
                    backgroundColor: colors.primary.main,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CheckIcon
                    sx={{
                      color: "white",
                      fontSize: 23,
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: 12,
                    right: 12,
                    width: 25,
                    height: 25,
                    borderRadius: 12.5,
                    backgroundColor: colors.error2,
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: "white",
                      fontSize: 23,
                    }}
                  />
                </Box>
              )}
              {res.type === "image" ? (
                <img
                  src={res.image}
                  alt=""
                  style={{ width: 126, height: 133, borderRadius: "20px" }}
                />
              ) : (
                <video
                  playsInline
                  src={res.image}
                  controls
                  style={{ borderRadius: 20, objectFit: "cover" }}
                  width={125}
                  height={133}
                />
              )}
              {res.type === "image" && res.isSuccess === false && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 126,
                    height: 133,
                    backgroundColor: "rgba(83, 83, 83, 0.6)",
                    borderRadius: "20px",
                  }}
                />
              )}
              <Typography
                sx={{
                  mt: "4px",
                  color:
                    res.isSuccess === true ? colors.text.light : colors.error2,
                  lineHeight: "20px",
                  fontWeight: 500,
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  letterSpacing: "-0.04em",
                }}
              >
                {res.name}
              </Typography>
            </Stack>
          ))}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: "absolute",
          bottom: "45px",
          left: "0px",
          width: "100%",
        }}
      >
        <Tooltip
          title="변환에 성공한 파일을 모두 다운로드 합니다."
          placement="top"
        >
          <Button
            disabled={
              tabValue === "실패" ||
              results?.filter((res) => res.isSuccess === true).length === 0
            }
            onClick={download}
            sx={{
              flexDirection: "row",
              backgroundColor: "#202020",
              width: "223px",
              height: "42px",
              borderRadius: "10px",
            }}
          >
            <SaveAltOutlinedIcon sx={{ color: "white", fontSize: 18 }} />
            <Typography
              sx={{
                color: "white",
                fontSize: 15,
                fontWeight: 700,
                letterSpacing: "-0.04em",
                ml: "10px",
              }}
            >
              DOWNLOAD
            </Typography>
          </Button>
        </Tooltip>
        <Stack direction="row">
          <Tooltip
            title="변환에 실패한 파일을 모두 다시 변환합니다."
            placement="top"
          >
            <Button
              onClick={reconvert}
              disabled={
                results?.filter((res) => res.isSuccess === false).length === 0
              }
              sx={{
                flexDirection: "row",
                backgroundColor: "#545454",
                width: "166px",
                height: "42px",
                borderRadius: "10px",
              }}
            >
              <RefreshOutlinedIcon sx={{ color: "white", fontSize: 15 }} />
              <Typography
                sx={{
                  color: "white",
                  fontSize: 15,
                  fontWeight: 700,
                  letterSpacing: "-0.04em",
                  ml: "7px",
                }}
              >
                SWAP AGAIN
              </Typography>
            </Button>
          </Tooltip>
          <Box width="9px" />
          <Button
            onClick={() => navigate("/contents")}
            sx={{
              flexDirection: "row",
              backgroundColor: colors.primary.main,
              width: "166px",
              height: "42px",
              borderRadius: "10px",
            }}
          >
            <DashboardOutlinedIcon sx={{ color: "white", fontSize: 15 }} />
            <Typography
              sx={{
                color: "white",
                fontSize: 15,
                fontWeight: 700,
                letterSpacing: "-0.04em",
                ml: "7px",
              }}
            >
              V CONTENTS
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ResultPage;
