import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction, useRef } from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import colors from "src/colors";
import { IDImageType } from "src/types/FileType";

type typeProps = {
  transType: string | null;
  setTransType: Dispatch<SetStateAction<string | null>>;
  idImg: IDImageType | null;
  setIdImg: Dispatch<SetStateAction<IDImageType | null>>;
};

const TypeSelect = (props: typeProps) => {
  const { transType, setTransType, idImg, setIdImg } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const fileUploaderAction = (e: ChangeEvent<HTMLInputElement>) => {
    const nativeEvent = e.nativeEvent.target as HTMLInputElement;
    const currentFiles = e.target.files;
    if (currentFiles && currentFiles[0]) {
      const result = URL.createObjectURL(
        new Blob([currentFiles[0]], { type: currentFiles[0].type })
      );
      const data = {
        file: currentFiles[0],
        preview: result,
      } as IDImageType;
      if (data) {
        setTransType("specific");
        setIdImg(data);
        nativeEvent.value = "";
      }
    }
  };
  return (
    <Stack direction="row" justifyContent="center" sx={{ mt: "56px" }}>
      <Stack
        onClick={() => {
          if (transType === "all") {
            setTransType(null);
          } else {
            setTransType("all");
          }
        }}
        sx={{
          backgroundColor:
            transType === "all" ? colors.primary.main : "#666666",
          width: "247px",
          height: "313.54px",
          borderRadius: "20px",
          pt: "75px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontWeight: 700,
            fontSize: 20,
            letterSpacing: "-0.04em",
          }}
        >
          모든 얼굴 변환
        </Typography>
        <PeopleAltOutlinedIcon
          sx={{ color: "white", fontSize: 56, my: "19px" }}
        />
        <Typography
          sx={{
            color: "white",
            fontSize: 14,
            letterSpacing: "-0.04em",
            textAlign: "center",
          }}
        >
          컨텐츠에 한명의 인물이 나온다면
          <br />
          사용을 추천드립니다.
        </Typography>
      </Stack>
      <Box width="60px" />
      <Tooltip title="이 얼굴만 변환을 하겠습니다." open={idImg !== null}>
        <Stack
          onClick={() => {
            if (!idImg) {
              fileRef.current?.click();
            }
          }}
          sx={{
            backgroundColor:
              transType === "specific" ? colors.primary.main : "#666666",
            width: "247px",
            height: "313.54px",
            borderRadius: "20px",
            pt: "75px",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
          }}
        >
          {idImg && (
            <img
              src={idImg.preview}
              alt=""
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                width: 227,
                height: 294,
                borderRadius: 10,
                objectFit: "cover",
              }}
            />
          )}
          {idImg && (
            <IconButton
              onClick={() => {
                setIdImg(null);
                setTransType(null);
              }}
              sx={{
                p: "15px",
                position: "absolute",
                top: "4px",
                right: "4px",
                zIndex: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "15px",
                  height: "15px",
                  borderRadius: "7.5px",
                  backgroundColor: "#3D3D3D",
                }}
              >
                <RemoveOutlinedIcon sx={{ color: "white", fontSize: 14 }} />
              </Box>
            </IconButton>
          )}
          <input
            ref={fileRef}
            multiple={false}
            accept="image/*"
            onChange={(e) => fileUploaderAction(e)}
            type="file"
            style={{ display: "none" }}
          />
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: 20,
              letterSpacing: "-0.04em",
            }}
          >
            특정 얼굴 변환
          </Typography>
          <PersonOutlineOutlinedIcon
            sx={{ color: "white", fontSize: 56, my: "19px" }}
          />
          <Typography
            sx={{
              color: "white",
              fontSize: 14,
              letterSpacing: "-0.04em",
              textAlign: "center",
            }}
          >
            컨텐츠에 여러명의 인물이 나온다면
            <br />
            사용을 추천드립니다.
          </Typography>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default TypeSelect;
