import React, { Dispatch, SetStateAction } from "react";
import { Box, Stack, Typography } from "@mui/material";
import colors from "src/colors";
import { currentVType, VFaceWithImage } from "src/types/FileType";

type selectType = {
  vFace: VFaceWithImage[] | null;
  setCurrentV: Dispatch<SetStateAction<currentVType | null>>;
  currentV: currentVType | null;
};

const VFaceSelect = (props: selectType) => {
  const { vFace, setCurrentV, currentV } = props;
  return (
    <Stack
      sx={{
        maxWidth: "1180px",
        overflowX: "scroll",
        overflowY: "hidden",
        margin: "36px auto 0px auto",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          display: "grid",
          gridTemplateRows: "1fr 1fr",
          gridAutoFlow: "column dense",
          gridTemplateColumns: "repeat(1fr)",
        }}
      >
        {vFace?.map((v, i) => (
          <Stack
            key={i}
            sx={{
              width: "126px",
              height: "172px",
              mx: "9px",
              mb: "28px",
              position: "relative",
            }}
          >
            <div
              onClick={() => {
                if (currentV?.image === v.source) {
                  setCurrentV(null);
                } else {
                  setCurrentV({
                    blob: v.blob,
                    image: v.source,
                    id: v.id,
                    name: v.name,
                  });
                }
              }}
              style={{
                width: 126,
                height: 133,
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor:
                  currentV?.image === v.source
                    ? colors.primary.main
                    : "transparent",
                borderRadius: 20,
                cursor: "pointer",
                zIndex: 1,
                opacity: 0.5,
              }}
            />
            <img
              style={{
                width: 126,
                height: 133,
                marginBottom: 7,
                borderRadius: 20,
              }}
              alt=""
              src={v.source}
            />
            <Typography
              sx={{
                textAlign: "center",
                lineHeight: "16px",
                color: colors.text.light,
                fontWeight: 500,
                letterSpacing: "-0.04em",
              }}
            >
              {v.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default VFaceSelect;
