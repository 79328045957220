const colors = {
  primary: {
    main: "#F15822",
  },
  secondary: {
    light: "#3D3D3D",
    main: "#222222",
  },
  text: {
    light: "#BABABA",
    main: "white",
  },
  error: "#FF3232",
  error2: "#F23737",
};
export default colors;
