import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Preview from "src/images/step/step04.svg";

const Step04 = () => {
  return (
    <Stack
      direction="row"
      sx={{ width: "1300px", margin: "0px auto", pl: "160px", py: "32px" }}
    >
      <Box
        sx={{
          border: "1px solid #BABABA",
          width: "159px",
          height: "181px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: "49px",
          position: "relative",
        }}
      >
        <img src={Preview} alt="" style={{ width: "109px", height: "78px" }} />
      </Box>
      <Stack sx={{ pt: "11px" }}>
        <Box
          sx={{
            border: "0.5px solid #FFFFFF",
            borderRadius: "12px",
            mb: "4px",
            width: "78px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: 12,
              letterSpacing: "-0.04em",
              lineHeight: "17px",
            }}
          >
            STEP 4
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "white",
            letterSpacing: "-0.04em",
            fontSize: 22,
            fontWeight: 700,
          }}
        >
          변환 결과물 확인
        </Typography>
        <Box height="16px" />
        <Typography sx={{ fontSize: 16, color: "#BABABA" }}>
          업로드 하신 파일이 모두 변환 되었습니다.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Step04;
