import { CircularProgress } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: 10,
      }}
    >
      <CircularProgress size={50} color="primary" />
    </div>
  );
};

export default Loading;
