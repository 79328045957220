/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      company
      field
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        field
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVFace = /* GraphQL */ `
  query GetVFace($id: ID!) {
    getVFace(id: $id) {
      id
      userID
      name
      image
      gender
      isBookmarked
      createdAt
      updatedAt
    }
  }
`;
export const listVFaces = /* GraphQL */ `
  query ListVFaces(
    $filter: ModelVFaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVFaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        name
        image
        gender
        isBookmarked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVContent = /* GraphQL */ `
  query GetVContent($id: ID!) {
    getVContent(id: $id) {
      id
      userID
      VFaceID
      fileName
      storage
      isBookmarked
      type
      createdAt
      updatedAt
    }
  }
`;
export const listVContents = /* GraphQL */ `
  query ListVContents(
    $filter: ModelVContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        VFaceID
        fileName
        storage
        isBookmarked
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVContentComplain = /* GraphQL */ `
  query GetVContentComplain($id: ID!) {
    getVContentComplain(id: $id) {
      id
      userID
      VFaceID
      VContentID
      description
      email
      isFinished
      createdAt
      updatedAt
    }
  }
`;
export const listVContentComplains = /* GraphQL */ `
  query ListVContentComplains(
    $filter: ModelVContentComplainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVContentComplains(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        VFaceID
        VContentID
        description
        email
        isFinished
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequestVFace = /* GraphQL */ `
  query GetRequestVFace($id: ID!) {
    getRequestVFace(id: $id) {
      id
      userID
      email
      faceDesc
      reference
      testContents
      who
      opinion
      isFinished
      createdAt
      updatedAt
    }
  }
`;
export const listRequestVFaces = /* GraphQL */ `
  query ListRequestVFaces(
    $filter: ModelRequestVFaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestVFaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        email
        faceDesc
        reference
        testContents
        who
        opinion
        isFinished
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vFaceByUser = /* GraphQL */ `
  query VFaceByUser(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVFaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    VFaceByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        image
        gender
        isBookmarked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vContentByUser = /* GraphQL */ `
  query VContentByUser(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    VContentByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        VFaceID
        fileName
        storage
        isBookmarked
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vContentByVFace = /* GraphQL */ `
  query VContentByVFace(
    $VFaceID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vContentByVFace(
      VFaceID: $VFaceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        VFaceID
        fileName
        storage
        isBookmarked
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
